export const permission = {
    methods: {
        /**
		 * 获取权限里面的子页面类目
		 * @param   {[type]}  page  [page description]
		 * @return  {[type]}        [return description]
		 */
        getChild (page) {
            const permission = JSON.parse(localStorage.getItem("permission")).frontPermission;
            return permission[page].child;
        },
        /**
		 * 获取权限里面的功能类目
		 * @param   {[type]}  page  [page description]
		 * @return  {[type]}        [return description]
		 */
        getFunction (page) {
            const permission = JSON.parse(localStorage.getItem("permission")).frontPermission;
            return permission[page].function;
        },
        /**
		 * 获取账号默认的broker数据
		 * @return {[type]} [description]
		 */
        loadDefaultBrokerData () {
            // 从本地权限里面拿到broker
            const data = JSON.parse(localStorage.getItem("permission"));

            // 赋值broker列表, 当前broker 名字和id
            const brokerArr = data.broker;
            const brokerId = data.broker[0].broker_id;
            const broker = data.broker[0].broker;

            return {
                brokerList: brokerArr,
                selectedBrokerId: brokerId,
                selectedBroker: broker
            };
        }
    }
};
