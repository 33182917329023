import { getDataSameDirection } from "@services/alert/same-direction";
import common from "@assets/js/common";
import dayjs from "dayjs";

const state = {
    autoRefreshTimeOffset: 1,
    loading: false,
    alertData: [],
    alertHeader: [
        { text: "Login", value: "login", align: "left" },
        { text: "Name", value: "name", align: "left" },
        { text: "Symbol", value: "symbol", align: "left" },
        { text: "Book", value: "book", align: "left" },
        { text: "Volume", value: "volume", align: "left" },
        { text: "Event", value: "event", align: "left" },
        { text: "Order", value: "order", align: "left" },
        { text: "Open Time", value: "open_time", align: "left", width: 170, },
        { text: "Close Time", value: "close_time", align: "left", width: 170, },
    ],
    alertDataAmount: 0,
    csvHeader: {
        Login: "login",
        Symbol: "symbol",
        Book: "book",
        Volume: "volume",
        Event: "event",
        Order: "order",
        "Open Time": "open_time",
        "Close Time": "close_time",
    },
    selectedDate: null,
};

const actions = {
    /**
     * Fetch large volume data
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  data    [data description]
     * @return  {[type]}          [return description]
     */
    getDataSameDirectionAction({ commit }, data) {
        commit("UPDATE_LOADING", true);
        getDataSameDirection(data).then((res) => {
            res.data.data.forEach((item) => {
                item.book = item.book.replaceAll(";", "");
            });
            commit("UPDATE_ALERT_DATA", res.data.data);
            commit("UPDATE_AMOUNT", res.data.count);
            commit("UPDATE_LOADING", false);
        });
    },
    processSameDirection({ commit }, data) {
        const minimumAcceptableTime = dayjs(
            common.getMT4Time().format("YYYY-MM-DD")
        ).unix();
        if (state.selectedDate >= minimumAcceptableTime) {
            data.book = data.book.replaceAll(";", "");
            const exist = state.alertData.find(
                (item) =>
                    item.login == data.login &&
                    item.book == data.book &&
                    item.symbol == data.symbol &&
                    item.order == data.order
            );
            if (!exist) {
                data["new"] = true;
                commit("UPDATE_ALERT_DATA", [data, ...state.alertData]);
            }
        }
    },
};

const mutations = {
    /**
     * Update alert data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
    UPDATE_SELECTED_DATE(state, data) {
        state.selectedDate = dayjs(data).unix();
    },
    UPDATE_AMOUNT(state, data) {
        state.alertDataAmount = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
