import { getRawHistory } from "@services/search/rawHistory";
import dayjs from "dayjs";

const state = {
    filterOrderBy: [
        "login",
        "ticket",
        "symbol",
        "cmd",
        "volume",
        "open_time",
        "open_price",
        "close_time",
        "close_price",
        "profit",
        "sl",
        "tp",
        "swaps",
        "commission",
        "comment",
        "reason",
        "platform",
    ],
    filterReasonLists: {
        MT4: [
            { value: 0, name: "Client" },
            { value: 1, name: "Expert" },
            { value: 2, name: "Dealer" },
            { value: 3, name: "Signal" },
            { value: 4, name: "Gateway" },
            { value: 5, name: "Mobile" },
            { value: 6, name: "Web" },
            { value: 7, name: "API" },
        ],
        MT5: [
            { value: 0, name: "CLIENT" },
            { value: 1, name: "EXPERT" },
            { value: 2, name: "DEALER" },
            { value: 3, name: "SL" },
            { value: 4, name: "TP" },
            { value: 5, name: "SO" },
            { value: 6, name: "ROLLOVER" },
            { value: 7, name: "EXTERNAL_CLIENT" },
            { value: 8, name: "VMARGIN" },
            { value: 9, name: "GATEWAY" },
            { value: 10, name: "SIGNAL" },
            { value: 11, name: "SETTLEMENT" },
            { value: 12, name: "TRANSFER" },
            { value: 13, name: "SYNC" },
            { value: 14, name: "EXTERNAL_SERVICE" },
            { value: 15, name: "MIGRATION" },
            { value: 16, name: "MOBILE" },
            { value: 17, name: "WEB" },
            { value: 18, name: "SPLIT" },
        ],
        CTRADER: [],
    },
    platformList: [{ name: "MT4" }, { name: "MT5" }, { name: "CTRADER" }],
    filterCmdLists: [
        { name: "Buy", cmd: 0 },
        { name: "Sell", cmd: 1 },
    ],
    csvHeader: {
        book: "Book",
        login: "Login",
        ticket: "Ticket",
        symbol: "Symbol",
        cmd: "Type",
        volume: "Volume",
        open_time: "OpenTime",
        open_price: "OpenPrice",
        close_time: "CloseTime",
        close_price: "ClosePrice",
        profit: "Profit",
        sl: "Sl",
        tp: "Tp",
        swaps: "Swaps",
        commission: "Commission",
        comment: "Comment",
        reason: "Reason",
    },
    headers: [
        { text: "Server", value: "server_name", align: "left", width: 100 },
        { text: "Login", value: "login", align: "left", width: 100 },
        { text: "Ticket", value: "ticket", align: "left", width: 100 },
        { text: "Symbol", value: "symbol", align: "left", width: 120 },
        { text: "Type", value: "cmd", align: "left", width: 90 },
        { text: "Volume", value: "volume", align: "right", width: 120 },
        { text: "Open Price", value: "open_price", align: "right", width: 140 },
        { text: "Open Time", value: "open_time", align: "left", width: 170 },
        {
            text: "Close Price",
            value: "close_price",
            align: "right",
            width: 140,
        },
        { text: "Close Time", value: "close_time", align: "left", width: 170 },
        { text: "Profit", value: "profit", align: "right", width: 100 },
        { text: "SL", value: "sl", align: "right", width: 80 },
        { text: "TP", value: "tp", align: "right", width: 80 },
        { text: "Swaps", value: "swaps", align: "right", width: 100 },
        { text: "Commission", value: "commission", align: "right", width: 140 },
        { text: "Comment", value: "comment", align: "left", width: 130 },
        { text: "Platform", value: "platform", align: "left", width: 100 },
        { text: "Reason", value: "reason", align: "left", width: 120 },
    ],
    loading: false,
    btnLoading: false,
    tradesList: [],
    openTimeMin: 20,
    openTimeMax: 50,
    openTimeRange: [20, 50],
    openTimePeriod: [],
    closeTimeMin: 20,
    closeTimeMax: 50,
    closeTimeRange: [20, 50],
    closeTimePeriod: [],
    dataAmount: 0,
    searchTutorial: false,
};

const actions = {
    getRawHistoryAction({ commit, state }, params) {
        commit("UPDATE_LOADING", true);
        commit("UPDATE_BTN_LOADING", true);
        commit("UPDATE_HISTORY", []);
        getRawHistory(params)
            .then((res) => {
                const data = res.data.data.map((item) => {
                    // console.log(item)
                    if (
                        state.filterReasonLists.hasOwnProperty(item.platform) &&
                        state.filterReasonLists[item.platform].hasOwnProperty(
                            item.reason
                        )
                    ) {
                        item.reason =
                            state.filterReasonLists[item.platform][
                                item.reason
                            ]?.name.toUpperCase();
                    } else {
                        item.reason = "Unknown";
                    }
                    item.open_price = item.open_price.toFixed(item.digits);
                    item.close_price = item.close_price.toFixed(item.digits);
                    item.sl = item.sl === 0 ? 0 : item.sl.toFixed(item.digits);
                    item.tp = item.tp === 0 ? 0 : item.tp.toFixed(item.digits);
                    item.cmd = state.filterCmdLists[item.cmd].name;

                    return item;
                });
                commit("UPDATE_HISTORY", data);
                commit("UPDATE_AMOUNT", res.data.count);
                commit("UPDATE_TIME_FACTORS");
                commit("UPDATE_LOADING", false);
                commit("UPDATE_BTN_LOADING", false);
            })
            .catch((e) => {
                console.log(e);
                commit("UPDATE_HISTORY", []);
                commit("UPDATE_LOADING", false);
                commit("UPDATE_AMOUNT", 0);
                commit("UPDATE_BTN_LOADING", false);
            });
    },
    requestRawHistoryAction({ commit, state }, params) {
        commit("UPDATE_BTN_LOADING", true);
        getRawHistory(params)
            .then((res) => {
                let snackbar = {
                    message: res.data.msg,
                    color: "green",
                    btnName: "Close",
                    status: true,
                    timeout: 2000,
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
                commit("UPDATE_BTN_LOADING", false);
            })
            .catch((e) => {
                console.log(e);
                let snackbar = {
                    message: "Fail to request csv",
                    color: "red",
                    btnName: "Close",
                    status: true,
                    timeout: 2000,
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
                commit("UPDATE_BTN_LOADING", false);
            });
    },
    updateSearchTutorialAction({ state }, data){
        state.searchTutorial = data
    },
};

const mutations = {
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_BTN_LOADING(state, data) {
        state.btnLoading = data;
    },
    UPDATE_HISTORY(state, data) {
        state.tradesList = data;
    },
    UPDATE_TIME_FACTORS(state) {
        for (const i of state.tradesList) {
            state.openTimePeriod.push(Date.parse(i.open_time));
            state.closeTimePeriod.push(Date.parse(i.close_time));
        }
        state.openTimeMin = Math.min.apply(Math, state.openTimePeriod);
        state.openTimeMax = Math.max.apply(Math, state.openTimePeriod);
        state.openTimeRange = [state.openTimeMin, state.openTimeMax];
        state.closeTimeMin = Math.min.apply(Math, state.closeTimePeriod);
        state.closeTimeMax = Math.max.apply(Math, state.closeTimePeriod);
        state.closeTimeRange = [state.closeTimeMin, state.closeTimeMax];
    },
    UPDATE_AMOUNT(state, data) {
        state.dataAmount = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
