import { updatePassword } from "@services/account/security";

const state = {
    loading: false
};

const actions = {
    updatePasswordAction({commit},params){
        commit("UPDATE_LOADING", true);
        updatePassword(params).then((res)=>{
            
            let snackbar = {
                message: "Password reset successful",
                color: "green",
                btnName: "Close",
                status: true,
                timeout: 2000,
            };
            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            commit("UPDATE_LOADING", false);
        })
    }
};

const mutations = {
    UPDATE_LOADING(state, data) {
        state.loading = data;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
