import request from "@utils/request";

export function getLPSymbolMap(query) {
    return request({
        url: "lp/map-symbol",
        method: "get",
        params: query
    });
}

export function BatchAddSymbolMap(params) {
    return request({
        url: "lp/map-symbol",
        method: "post",
        data: params
    });
}

export function updateLPSymbolMap(params) {
    return request({
        url: "lp/map-symbol",
        method: "put",
        data: params
    });
}

export function deleteLPSymbolMap(params) {
    return request({
        url: "lp/map-symbol",
        method: "delete",
        data: params
    });
}
