import dayjs from "dayjs";
// import utc from "dayjs/plugin/utc";
// import timezone from "dayjs/plugin/timezone";
// import duration from "dayjs/plugin/duration";

// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.extend(duration);
// dayjs().utcOffset(timezoneGmt * 60)

// let timezoneGmt = process.env.TIMEZONE_GMT;
// let timezoneGmt = 3;

export default {
    /**
     * Convert unix timestamp to datetime string format
     * @param   {[type]}  timestamp  [timestamp description]
     * @return  {[type]}             [return description]
     */
    convertUnixtimeToDatetimeString(timestamp) {
        const current = new Date(timestamp * 1000);

        let result = "";
        let month = "" + (current.getMonth() + 1);
        let day = "" + current.getDate();
        const year = current.getFullYear();

        const hour =
            current.getHours() < 10
                ? "0" + current.getHours()
                : current.getHours();
        const minutes =
            current.getMinutes() < 10
                ? "0" + current.getMinutes()
                : current.getMinutes();
        const sec =
            current.getSeconds() < 10
                ? "0" + current.getSeconds()
                : current.getSeconds();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        result = [year, month, day].join("-");
        result += " ";
        result += hour + ":" + minutes + ":" + sec;

        return result;
    },
    /**
     * Filter alert by broker's book
     * @param   {[type]}  data      [data description]
     * @param   {[type]}  brokerId  [brokerId description]
     * @return  {[type]}            [return description]
     */
    globalAlertFilter(data, brokerId) {
        let filterBook = localStorage.getItem("alertHideBroker");
        if (
            filterBook !== undefined &&
            filterBook !== null &&
            data !== undefined
        ) {
            filterBook = JSON.parse(filterBook);

            if (filterBook.hasOwnProperty(brokerId)) {
                let filterBrokerBook = filterBook[brokerId];
                let result = data.filter(
                    (item) => !filterBrokerBook.includes(item.book)
                );
                return result;
            }
            return data;
        }
    },
    /**
     * Escape json string new line
     * @param   {[type]}  str  [str description]
     *
     * @return  {[type]}       [return description]
     */
    jsonEscape(str) {
        return str
            .replace(/\n/g, "\\\\n")
            .replace(/\r/g, "\\\\r")
            .replace(/\t/g, "\\\\t");
    },
    toSydneyTime(timeStr) {
        var utc = require("dayjs/plugin/utc");
        var timezone = require("dayjs/plugin/timezone");
        dayjs.extend(utc);
        dayjs.extend(timezone);
        return dayjs(timeStr).tz("Australia/Sydney");
    },
    /**
     * Get current mt4 time in date format
     * @return  {[object]}  Current mt4 time in date format
     */
    getMT4Time() {
        let utc = require("dayjs/plugin/utc");
        let timezone = require("dayjs/plugin/timezone");
        dayjs.extend(utc);
        dayjs.extend(timezone);
        return dayjs().tz("US/Eastern").add(7, "hour");
    },
    /**
     * Get current mt4 time in string
     * @return  {[type]}  [return description]
     */
    getMT4TimeString() {
        let utc = require("dayjs/plugin/utc");
        let timezone = require("dayjs/plugin/timezone");
        dayjs.extend(utc);
        dayjs.extend(timezone);
        return dayjs()
            .tz("US/Eastern")
            .add(7, "hour")
            .format("YYYY-MM-DD HH:mm:ss");
    },
    /**
     * Check if server time is in weekday or not
     * @return  {[type]}  [return description]
     */
    isWeekday() {
        let utc = require("dayjs/plugin/utc");
        let timezone = require("dayjs/plugin/timezone");
        dayjs.extend(utc);
        dayjs.extend(timezone);
        let daysOfWeek = dayjs().tz("US/Eastern").day();
        const weekDay = [1, 2, 3, 4, 5];
        if (weekDay.includes(daysOfWeek)) return true;
        return false;
    },
    numberWithCommas(x) {
        const parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
        // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
};
