import request from "@utils/request";

export function getBroker(query) {
    return request({
        url: 'broker',
        method: 'get',
        params: query
    })
}

export function postBroker(query) {
    return request({
        url: 'broker',
        method: 'post',
        data: query
    })
}
export function putBroker(query) {
    return request({
        url: 'broker',
        method: 'put',
        data: query
    })
}
export function deleteBroker(query) {
    return request({
        url: 'broker',
        method: 'delete',
        data: query
    })
}
