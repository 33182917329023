export const snackbar = {
    methods: {
        /**
		 * Toggle snack bar message for success
		 * @param  {[type]} message [description]
		 * @return {[type]}         [description]
		 */
        snackBarSuccess (message, btnName="Close", timout=2000, callfromModules=undefined) {
            if (callfromModules) {
                callfromModules.snackmsg = message;
                callfromModules.snackcolor = "green";
                callfromModules.snackbarbtn = btnName;
                callfromModules.snackbar = true;
                callfromModules.snacktimeout = timout;
            } else {
                this.$store.state.snackmsg = message;
                this.$store.state.snackcolor = "green";
                this.$store.state.snackbarbtn = btnName;
                this.$store.state.snackbar = true;
                this.$store.state.snacktimeout = timout;
            }
        },

        /**
		 * Toggle snack bar message for danger
		 * @param  {[type]} message [description]
		 * @return {[type]}         [description]
		 */
        snackBarDanger (message, btnName="Close", timout=2000) {
            this.$store.state.snackmsg = message;
            this.$store.state.snackcolor = "red";
            this.$store.state.snackbarbtn = btnName;
            this.$store.state.snackbar = true;
            this.$store.state.snacktimeout = timout;
        },

        /**
		 * Toggle snack bar message for info
		 * @param  {[type]} message [description]
		 * @return {[type]}         [description]
		 */
        snackBarInfo (message,timeout=2000) {
            this.$store.state.snackbar = true;
            this.$store.state.snackmsg = message;
            this.$store.state.snackcolor = "blue";
            this.$store.state.snacktimeout = timeout;
        },
        snackBarWarning (message, btnName="Close", timout=3000) {
            this.$store.state.snackmsg = message;
            this.$store.state.snackcolor = "orange";
            this.$store.state.snackbarbtn = btnName;
            this.$store.state.snackbar = true;
            this.$store.state.snacktimeout = timout;
        },
    }
};
