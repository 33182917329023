const state = {
    autoRefreshTimeOffset: 1,
    loading: false,
    alertData: [],
    alertHeader: [
        { text: "Login", value: "user", align: "left" },
        { text: "Name", value: "name", align: "left" },
        { text: "Symbol", value: "symbol", align: "left" },
        { text: "Books", value: "books", align: "left" },
        {
            text: "Locking Pos",
            value: "lockingVolume",
            align: "right",
        },
    ],
    csvHeader: {
        Login: "user",
        Symbol: "symbol",
        Books: "books",
        "Locking Position": "lockingVolume",
        "Locking Position Threshold": "lockingVolumeThreshold",
    },
    selectedDate: null,
};

const actions = {
    processLockingPosition({ commit }, data) {
        let result = [];
        for (let item in data) {
            let temp = data[item];
            temp.books = temp.books.join(",");
            temp.name = item.split(":").slice(3, -1).join(" ");

            result.push(temp);
        }

        commit("UPDATE_ALERT_DATA", result);
    },
};

const mutations = {
    /**
     * Update alert data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
