import request from "@utils/request";

export function getPermission() {
        return request({
        url: 'permission',
        method: 'get',
    })
}

export function postPermission(data) {
    return request({
        url: 'permission',
        method: 'post',
        data: data
    })
}

export function putPermission(data){
    return request({
        url: 'permission',
        method: 'put',
        data: data
    })
}

export function deletePermission(data){
    return request({
        url: 'permission',
        method: 'delete',
        data: data
    })
}

