<template>
    <div class="pl-0" v-show="onDisplay">
        <p class="mt-0 mb-0
        " :id="timezoneName">
            <b>{{ timezoneName }}</b>: {{ currentTime }}
        </p>
    </div>
</template>
<script>
import { helper } from "@components/mixins/helper";

export default {
    components: {},
    mixins: [helper],
    props: {
        name: "",
        timeZone: 0,
        DST: false,
    },
    data() {
        return {
            timezoneName: "",
            currentTime: null,
            interval: null,
            onDisplay: true,
        };
    },
    mounted() {
        this.timezoneName = this.name;
        this.interval = setInterval(() => {
            const localtime = new Date();

            if (this.timezoneName === "SYD")
                this.currentTime = localtime.toLocaleString(undefined, {
                    timeZone: "Australia/Sydney",
                });
            if (this.timezoneName === "MT") {
                this.currentTime = this.getMT4Time();
            }

            if (this.timezoneName === "BJ")
                this.currentTime = localtime.toLocaleString(undefined, {
                    timeZone: "Asia/Shanghai",
                });
            if (this.timezoneName === "NY")
                this.currentTime = localtime.toLocaleString(undefined, {
                    timeZone: "America/New_York",
                });

            if (this.timezoneName === "LDN") {
                this.currentTime = localtime.toLocaleString(undefined, {
                    timeZone: "Europe/London",
                });
            }if (this.timezoneName === "JKT") {
                this.currentTime = localtime.toLocaleString(undefined, {
                    timeZone: "Asia/Jakarta",
                });
            }
            // if (document.body.clientWidth < 1550) {
            //     this.currentTime = this.currentTime.split(", ")[1];
            // }
            // if (document.body.clientWidth < 550) {
            //     this.onDisplay = false || this.timezoneName === "MT4";
            // } else {
            //     this.onDisplay = true;
            // }
        }, 1000);
    },
    destroyed() {
        clearInterval(this.interval);
    },
};
</script>
