import {
    getRouting,
    postRouting,
    putRouting,
    deleteRouting
} from "@services/routing";

const state = {
    newData: null,
    loading: false,
    routingData: [],
    routingHeader: [
        { text: "", sortable: false },
        { text: "ID", value: "id", align: "left" },
        { text: "Server", value: "server_name", align: "left" },
        { text: "Filter Login", value: "filter_login", align: "left" },
        { text: "Filter Group", value: "filter_group", align: "left" },
        { text: "Filter Symbol", value: "filter_symbol", align: "left" },
        {
            text: "Filter Symbol Group",
            value: "filter_symbol_group",
            align: "left"
        },
        { text: "Percent A", value: "percentage_a", align: "left" },
        { text: "Percent B", value: "percentage_b", align: "left" },
        { text: "Book A", value: "book_a_name", align: "left" },
        { text: "Book B", value: "book_b_name", align: "left" },
        { text: "Created At", value: "created_at", align: " d-none" },
        { text: "Updated At", value: "updated_at", align: " d-none" },
        { text: "Is Override", value: "is_override", align: "left" },
        { text: "Actions", value: "action", align: "left", width: "100px" }
    ],
    removeHeader: [
        { text: "ID", value: "id", align: " d-none" },
        { text: "Server", value: "server_name", align: "left" },
        { text: "Filter Login", value: "filter_login", align: "left" },
        { text: "Filter Group", value: "filter_group", align: "left" },
        { text: "Filter Symbol", value: "filter_symbol", align: "left" },
        {
            text: "Filter Symbol Group",
            value: "filter_symbol_group",
            align: "left"
        },
        { text: "Percent A", value: "percentage_a", align: "left" },
        { text: "Percent B", value: "percentage_b", align: "left" },
        { text: "Book A", value: "book_a_name", align: "left" },
        { text: "Book B", value: "book_b_name", align: "left" },
        { text: "Is Override", value: "is_override", align: "left" }
    ],
    removeData: [],
    csvFields: {
        ID: "id",
        Priority: "priority",
        "Server ID": "server_id",
        "Server Name": "server_name",
        "Filter Login": "filter_login",
        "Filter Group": "filter_group",
        "Filter Symbol": "filter_symbol",
        "Filter Symbol Group": "filter_symbol_group",
        "Percentage A": "percentage_a",
        "Percentage B": "percentage_b",
        "Book A ID": "book_a_id",
        "Book A Name": "book_a_name",
        "Book B ID": "book_b_id",
        "Book B Name": "book_b_name",
        "Is Override": "is_override"
    }
};

const actions = {
    /**
     * Action to get routings
     *
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  params  [params description]
     * @return  {[type]}          [return description]
     */
    getRoutingsAction({ commit }) {
        commit("UPDATE_LOADING", true);
        getRouting()
            .then((res) => {
                commit("UPDATE_ROUTING", res.data);
                commit("UPDATE_LOADING", false);
            })
            .catch((e) => commit("UPDATE_LOADING", false));
    },
    /**
     * Create new routing profile
     * @param   {[type]}  commit    [commit description]
     * @param   {[type]}  dispatch  [dispatch description]
     * @param   {[type]}  params    [params description]
     * @return  {[type]}            [return description]
     */
    postRoutingAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        postRouting(params).then((res) => {
            if (res.status === 200) {
                const snackbar = {
                    message: res.data.msg,
                    color: "green",
                    btnName: "Close",
                    status: true,
                    timeout: 2000
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
                commit("UPDATE_NEW_DATA", res.data.data);
                commit("UPDATE_LOADING", false);
            }
        }).catch(e => {
            console.log("e", e);
            commit("UPDATE_LOADING", false);
        });
    },
    /**
     * Update routing profile
     * @param   {[type]}  commit    [commit description]
     * @param   {[type]}  dispatch  [dispatch description]
     * @param   {[type]}  params    [params description]
     * @return  {[type]}            [return description]
     */
    putRoutingAction({ commit, dispatch }, params) {
        commit("UPDATE_LOADING", true);
        const snackbar = {
            message:
                "Updating routing config normally takes some time, please wait for response..... ",
            color: "orange",
            btnName: "Close",
            status: true,
            timeout: 6000
        };
        commit("UPDATE_SNACKBAR", snackbar, { root: true });
        putRouting(params).then((res) => {
            const snackbar = {
                message: res.data.msg,
                color: "green",
                btnName: "Close",
                status: true,
                timeout: 2000
            };
            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            commit("UPDATE_LOADING", false);
            dispatch("getRoutingsAction");
        }).catch(e => {
            console.log("e", e);
            commit("UPDATE_LOADING", false);
        });
    },
    /**
     * Remove row data action
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  params  [params description]
     * @return  {[type]}          [return description]
     */
    deleteRoutingAction({ commit, dispatch }, target) {
        commit("UPDATE_LOADING", true);
        deleteRouting(target).then((res) => {
            const snackbar = {
                message: res.data.msg,
                color: "green",
                btnName: "Close",
                status: true,
                timeout: 2000
            };
            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            commit("UPDATE_LOADING", false);
            dispatch("getRoutingsAction");
        });
    }
};

const mutations = {
    /**
    /**
     * Update books
     *
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ROUTING(state, data) {
        state.routingData = data;
    },
    /**
     * Update loading
     *
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    /**
     * Assign remove data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_REMOVE_DATA(state, data) {
        state.removeData = data;
    },
    UPDATE_NEW_DATA(state, data) {
        state.newData = data;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
