import request from "@utils/request";

export function login(query) {
    return request({
        url: "auth",
        method: "post",
        data: query
    });
}

export function logUserOut(query){
    return request({
        url: "auth",
        method: "delete",
        data: query
    });
}

export function putAuthUser(query) {
    return request({
        url: "auth",
        method: "put",
        data: query
    });
}