import { getCopyTradeData } from "@services/copy-trade/data";
const state = {
    loading: false,
    tableHeader: [
        { text: "Ticket", value: "ticket", align: "left", width: 60 },
        { text: "Type", value: "cmd", align: "left", width: 60 },
        { text: "Lots", value: "volume", align: "right", width: 60 },
        { text: "Open Time", value: "open_time", align: "left", width: 170 },
        { text: "Open Price", value: "open_price", align: "right", width: 100 },
        { text: "Reason", value: "reason", align: "left", width: 60 },
        { text: "Comment", value: "comment", align: "left", width: 200 },
    ],
    copyTradeData: [],
    reasonList: {
        0: "Client",
        1: "Expert",
        2: "Dealer",
        3: "Signal",
        4: "Gateway",
        5: "Mobile",
        6: "Web",
        7: "API",
    },
};

const actions = {
    getCopyTradeDataAction({ commit }, param) {
        commit("UPDATE_LOADING", true);
        getCopyTradeData(param)
            .then((res) => {
                commit("UPDATE_COPY_TRADE_DATA", res?.data || []);

                commit("UPDATE_LOADING", false);
            })
            .catch(() => {
                let snackbar = {
                    message: "No Data Found",
                    color: "red",
                    btnName: "Close",
                    status: true,
                    timeout: 2000,
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
            });
    },
};

const mutations = {
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_COPY_TRADE_DATA(state, data) {
        state.copyTradeData = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
