<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                small
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                x-small
                :disabled="isBtnDisabled"
                id="alertPageProfile"
                @click="UPDATE_CLICKED_OUTSIDE(true)"
                style="display: block; float: right"
            >
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="white"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            style="margin-right: 5px"
                            small
                        >
                            mdi-information
                        </v-icon>
                    </template>
                    <span>Customize alert page elements.</span>
                </v-tooltip>
                {{ selectedProfile.text }}
            </v-btn>
        </template>
        <v-list v-model="menu" dense>
            <div
                v-for="(item, index) in allProfile"
                :key="index"
                v-show="allProfile[0].id != 0"
            >
                <v-list-item>
                    <v-list-item-content
                        @click="changeSelectedProfile(item.text)"
                    >
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider />
            </div>
            <v-dialog v-model="editingDialog" width="1200" :persistent="true">
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                        dense
                        color="red lighten-2"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        v-show="allProfile[0].id != 0"
                        @click="assignEditingProfile"
                    >
                        <v-list-item-icon style="margin-right: 0">
                            <v-icon small>mdi-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                        dense
                        color="red lighten-2"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="assignAddingProfile"
                    >
                        <v-list-item-icon style="margin-right: 0">
                            <v-icon small>mdi-plus-thick</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Add</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>

                <v-card :loading="loading" id="editDialog">
                    <v-app-bar
                        flat
                        color="rgba(0, 0, 0, 0)"
                        style="background: rgb(26, 57, 88)"
                    >
                        <v-toolbar-title class="text-h6 white--text pl-0">{{
                            isEditing ? "Edit Profile" : "Add Profile"
                        }}</v-toolbar-title>
                    </v-app-bar>

                    <v-card-text style="padding-top: 20px; padding-bottom: 0">
                        <v-row style="margin-bottom: 1px">
                            <v-col v-if="isEditing" cols="6"
                                ><v-select
                                    dense
                                    v-model="editingProfile"
                                    :items="allProfile"
                                    item-text="text"
                                    item-value="id"
                                    label="Profile name"
                                    hide-details
                                ></v-select
                            ></v-col>
                            <v-col cols="6"
                                ><v-text-field
                                    dense
                                    label="New name"
                                    :placeholder="dialogNamePlaceholder"
                                    v-model="newName"
                                    hide-details
                                ></v-text-field
                            ></v-col>
                        </v-row>

                        <v-row style="margin-top: 0px; margin-bottom: 1px">
                            <v-col
                                cols="12"
                                style="padding-top: 0px; padding-bottom: 0px"
                            >
                                <v-autocomplete
                                    label="Select books that show on the alert page."
                                    :items="books"
                                    v-model="selectedBooks"
                                    multiple
                                    auto-select-first
                                    chips
                                    clearable
                                    deletable-chips
                                    small-chips
                                    hide-details
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-tabs v-model="tab">
                            <v-tab
                                v-for="item in Object.keys(allAlertOptions)"
                                :key="item"
                                style="padding: 0 7px"
                            >
                                {{ allAlertOptions[item].name }}
                            </v-tab>
                        </v-tabs>
                        <v-alert
                            dense
                            outlined
                            type="info"
                            style="margin-top: 2px"
                        >
                            Choose a tab and make selections below to determine
                            what to show in this profile
                        </v-alert>
                        <v-tabs-items v-model="tab">
                            <v-tab-item
                                v-for="item in Object.keys(allAlertOptions)"
                                :key="item"
                            >
                                <v-card style="padding: 10px">
                                    <v-row>
                                        <v-col
                                            cols="3"
                                            v-for="columnName in Object.keys(
                                                editingProfile.settings[item] ||
                                                    {}
                                            )"
                                            :key="columnName"
                                        >
                                            <v-row>
                                                <v-switch
                                                    hide-details
                                                    dense
                                                    style="margin-top: 0px; margin-bottom: 6px;"
                                                    v-model="
                                                        editingProfile.settings[
                                                            item
                                                        ][columnName].showing
                                                    "
                                                    :label="
                                                        editingProfile.settings[
                                                            item
                                                        ][columnName].text
                                                    "
                                                ></v-switch>
                                            </v-row>
                                            <!-- number type -->
                                            <!-- <v-row
                                                v-if="
                                                    editingProfile.settings[
                                                        item
                                                    ][columnName].type ===
                                                    'number'
                                                "
                                            >
                                                <v-col cols="6"
                                                    ><v-text-field
                                                        dense
                                                        :disabled="
                                                            !editingProfile
                                                                .settings[item][
                                                                columnName
                                                            ].showing
                                                        "
                                                        label="Min"
                                                        :rules="[rules.int]"
                                                        v-model="
                                                            editingProfile
                                                                .settings[item][
                                                                columnName
                                                            ].min
                                                        "
                                                    ></v-text-field
                                                ></v-col>
                                                <v-col cols="6"
                                                    ><v-text-field
                                                        dense
                                                        :disabled="
                                                            !editingProfile
                                                                .settings[item][
                                                                columnName
                                                            ].showing
                                                        "
                                                        label="Max"
                                                        :rules="[rules.int]"
                                                        v-model="
                                                            editingProfile
                                                                .settings[item][
                                                                columnName
                                                            ].max
                                                        "
                                                    ></v-text-field
                                                ></v-col>
                                            </v-row> -->

                                            <!-- string type -->
                                            <!-- <v-row
                                                v-if="
                                                    editingProfile.settings[
                                                        item
                                                    ][columnName].type ===
                                                    'string'
                                                "
                                            >
                                                <v-col cols="12"
                                                    ><v-text-field
                                                        dense
                                                        :disabled="
                                                            !editingProfile
                                                                .settings[item][
                                                                columnName
                                                            ].showing
                                                        "
                                                        label="Filter term"
                                                        hide-details
                                                        v-model="
                                                            editingProfile
                                                                .settings[item][
                                                                columnName
                                                            ].filter_term
                                                        "
                                                    ></v-text-field
                                                ></v-col>
                                            </v-row> -->

                                            <!-- time type -->
                                            <!-- <v-row
                                                v-if="
                                                    editingProfile.settings[
                                                        item
                                                    ][columnName].type ===
                                                    'time'
                                                "
                                            >
                                                <v-col
                                                    cols="1"
                                                    class="pt-0 pb-0"
                                                >
                                                    <v-subheader
                                                        style="
                                                            padding: 20px 0px
                                                                0px 0px;
                                                        "
                                                        >From:</v-subheader
                                                    >
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-text-field
                                                        dense
                                                        v-model="
                                                            editingProfile
                                                                .settings[item][
                                                                columnName
                                                            ].from
                                                        "
                                                        :disabled="
                                                            !editingProfile
                                                                .settings[item][
                                                                columnName
                                                            ].showing
                                                        "
                                                        type="time"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="1"
                                                    class="pt-0 pb-0"
                                                >
                                                    <v-subheader
                                                        style="
                                                            padding: 20px 0px
                                                                0px 0px;
                                                        "
                                                        >To:</v-subheader
                                                    >
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-text-field
                                                        dense
                                                        v-model="
                                                            editingProfile
                                                                .settings[item][
                                                                columnName
                                                            ].to
                                                        "
                                                        :disabled="
                                                            !editingProfile
                                                                .settings[item][
                                                                columnName
                                                            ].showing
                                                        "
                                                        type="time"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-spacer></v-spacer>
                                                <v-col
                                                    cols="1"
                                                    class="pt-0 pb-0"
                                                >
                                                    <v-tooltip top>
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs,
                                                            }"
                                                        >
                                                            <v-icon
                                                                color="grey"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                style="
                                                                    margin-top: 20px;
                                                                "
                                                                @click="
                                                                    editingProfile.settings[
                                                                        item
                                                                    ][
                                                                        columnName
                                                                    ].from =
                                                                        '00:00';
                                                                    editingProfile.settings[
                                                                        item
                                                                    ][
                                                                        columnName
                                                                    ].to =
                                                                        '23:59';
                                                                "
                                                            >
                                                                mdi-refresh</v-icon
                                                            >
                                                        </template>
                                                        <span
                                                            >Click to reset time
                                                            to default</span
                                                        >
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row> -->
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="grey" text @click="editingDialog = false">
                            Cancel
                        </v-btn>

                        <template>
                            <v-menu
                                v-model="confirmMenu"
                                absolute
                                :position-x="x"
                                :position-y="y"
                                persistent
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-show="isEditing"
                                        v-on="on"
                                        color="red"
                                        text
                                        :disabled="editingProfile == null"
                                        @click="onDelete"
                                        :loading="loading"
                                    >
                                        Delete
                                    </v-btn>
                                </template>

                                <v-card width="450">
                                    <v-card-title style="color: orange">
                                        Are you sure you want to delete this
                                        profile?
                                    </v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            @click="confirmMenu = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="confirmDeletion"
                                        >
                                            Confirm
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </template>

                        <v-btn
                            color="green"
                            text
                            @click="updateProfile"
                            :loading="loading"
                        >
                            {{ isEditing ? "Update" : "Add" }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-list>
    </v-menu>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { snackbar } from "@components/mixins/snackbar";
import validator, { rules } from "@assets/js/validator";
import deepClone from "deep-clone";

export default {
    mixins: [snackbar],
    computed: {
        ...mapState("Alert", [
            "allProfile",
            "selectedProfile",
            "allAlertOptions",
            "updated",
            "isBtnDisabled",
            "loading",
            "isBtnDisabled",
            "clickedOutside",
            "defaultSetting",
            "lastAddition",
            "books",
            "settings",
        ]),
        dialogNamePlaceholder() {
            return this.isEditing
                ? "Enter a new name if needed."
                : "Please enter a profile name";
        },
    },
    data() {
        return {
            editingDialog: null,
            menu: false,
            isEditing: true,
            allBooks: null,
            editingProfile: {
                settings: {},
            },
            newName: null,
            confirmMenu: false,
            x: 1200,
            y: 300,
            tab: null,
            rules: rules,
            selectedBooks: [],
        };
    },
    methods: {
        ...mapActions("Alert", [
            "getProfileAction",
            "getBooksPkAction",
            "putProfileAction",
            "postProfileAction",
            "deletePageProfileAction",
        ]),
        ...mapMutations("Alert", [
            "UPDATE_SELECTED_PROFILE",
            "UPDATE_ADDING_DIALOG",
            "UPDATE_ALL_ALERT_OPTIONS",
            "CHANGE_UPDATE_STATUS",
            "UPDATE_BTN_STATUS",
            "UPDATE_CLICKED_OUTSIDE",
            "UPDATE_LAST_ADDITION",
            "UPDATE_ALL_PROFILE",
        ]),
        changeSelectedProfile(profileName) {
            this.UPDATE_SELECTED_PROFILE(
                deepClone(
                    this.allProfile.filter(
                        (profile) => profile.text == profileName
                    )[0]
                )
            );
        },
        onDelete() {
            this.confirmMenu = false;
            //dialog x position + half of the dialog width minus half of the confirm dialog width
            this.x =
                document.getElementById("editDialog").getBoundingClientRect()
                    .x + 375;
            this.y =
                document.getElementById("editDialog").getBoundingClientRect()
                    .y + 125;
            this.$nextTick(() => {
                this.confirmMenu = true;
            });
        },
        assignEditingProfile() {
            this.newName = null;
            this.isEditing = true;
            this.editingProfile = null;
            this.editingProfile = this.selectedProfile;
            this.editingDialog = true;
        },
        assignAddingProfile() {
            this.UPDATE_LAST_ADDITION(null);
            this.newName = null;
            this.isEditing = false;
            this.editingProfile = {
                settings: deepClone(this.defaultSetting.settings),
            };

            this.editingDialog = true;
        },
        updateProfile() {
            const params = { value: {} };
            const unselectedBooks = [];
            this.books.map((book) => {
                if (!this.selectedBooks.includes(book)) {
                    unselectedBooks.push(book);
                }
            });
            //editing
            if (this.isEditing) {
                params.id = this.editingProfile.id;

                if (
                    this.newName == null ||
                    this.newName == this.editingProfile.text ||
                    this.newName == ""
                ) {
                    params.profile_name = this.editingProfile.text;
                } else {
                    this.newName = validator.cleanUpStringData(this.newName);
                    if (
                        this.allProfile.filter(
                            (profile) => profile.text == this.newName
                        ).length != 0 ||
                        this.newName.trim() == ""
                    ) {
                        this.snackBarDanger(
                            "The new profile name is invalid, please use another one."
                        );
                        return;
                    } else {
                        params.profile_name = this.newName;
                    }
                }
                params.value = {
                    settings: this.editingProfile.settings,
                    positions: this.editingProfile.positions,
                    unselected: this.editingProfile.unselected,
                    unselectedBooks: unselectedBooks,
                };
                this.putProfileAction(params);
            }
            //creating
            else {
                params.meta_value = "alert";
                if (!validator.commonStringValidator(this.newName)) {
                    this.snackBarDanger(
                        "The profile name is invalid, please enter a new name."
                    );
                    return;
                }
                this.newName = validator.cleanUpStringData(this.newName);
                if (
                    this.allProfile.filter(
                        (profile) => profile.text == this.newName
                    ).length != 0
                ) {
                    this.snackBarDanger(
                        "The profile name already exist, please enter a new name."
                    );
                    return;
                } else {
                    params.profile_name = this.newName;
                }
                params.value.positions = deepClone(this.settings);
                params.value.unselected = [...this.selectedProfile.unselected];
                params.value.settings = this.editingProfile.settings;
                params.value.unselectedBooks = unselectedBooks;
                this.postProfileAction(params);
            }
        },
        confirmDeletion() {
            const params = { id: this.editingProfile.id };
            this.deletePageProfileAction(params);
            this.editingProfile = this.selectedProfile;
        },
    },
    watch: {
        books(nv) {
            this.selectedBooks = [...nv];
            const profileList = localStorage.getItem("alertProfiles");
            if (profileList) {
                this.UPDATE_BTN_STATUS(true);
                const recordedAll = JSON.parse(profileList);
                this.UPDATE_ALL_PROFILE(recordedAll);
                const alertId = parseInt(localStorage.getItem("alertId"));
                if (alertId) {
                    if (recordedAll.find((item) => item.id === alertId)) {
                        this.UPDATE_SELECTED_PROFILE(
                            recordedAll.find((item) => item.id === alertId)
                        );
                    } else {
                        this.UPDATE_SELECTED_PROFILE(recordedAll[0]);
                    }
                } else {
                    this.UPDATE_SELECTED_PROFILE(recordedAll[0]);
                }
            } else {
                this.getProfileAction({ meta_value: "alert" });
            }
        },
        selectedProfile(nv) {
            if (this.books.length !== 0) {
                if (nv.unselectedBooks) {
                    const returnBooks = [];
                    this.books.map((book) => {
                        if (!nv.unselectedBooks.includes(book)) {
                            returnBooks.push(book);
                        }
                    });
                    this.selectedBooks = returnBooks;
                }
            }
        },
        editingProfile(nv) {
            if (nv != null) {
                if (this.allProfile) {
                    let newValue = null;
                    if (typeof nv === "number") {
                        newValue = this.allProfile.filter(
                            (profile) => profile.id == nv
                        )[0];
                        this.editingProfile = newValue;
                        const returnBooks = [];
                        this.books.map((book) => {
                            if (!newValue.unselectedBooks.includes(book)) {
                                returnBooks.push(book);
                            }
                        });
                        this.selectedBooks = returnBooks;
                    }
                }
            }
        },
        allProfile(nv) {
            if (nv[0].id == 0) {
                this.editingDialog = false;
            }
        },
        updated(nv, ov) {
            if (nv) {
                this.editingDialog = false;
                this.getProfileAction({ meta_value: "alert" });
                this.CHANGE_UPDATE_STATUS(false);
            }
        },
        tab(nv) {
            setTimeout(() => {
                this.$nextTick(() => {});
            }, 50);
        },
    },
    mounted() {
        this.CHANGE_UPDATE_STATUS(false);
        this.getBooksPkAction();
    },
};
</script>

<style>
::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
.v-input--selection-controls .v-input__slot .v-label {
    font-weight: 800;
}
</style>
