import request from "@utils/request";

export function getConfig(query) {
    return request({
        url: 'notification',
        method: 'get',
        params: query
    })
}

export function postConfig(query) {
    return request({
        url: 'notification',
        method: 'post',
        data: query
    })
}
export function putConfig(query) {
    return request({
        url: 'notification',
        method: 'put',
        data: query
    })
}
export function deleteConfig(query) {
    return request({
        url: 'notification',
        method: 'delete',
        data: query
    })
}
