import request from "@utils/request";

export function getUsers (query) {
    return request({
        url: "user",
        method: "get",
        params: query
    });
}

export function postUsers (query) {
    return request({
        url: "user",
        method: "post",
        data: query
    });
}

export function putUsers (query) {
    return request({
        url: "user",
        method: "put",
        data: query
    });
}

export function deleteUsers (query) {
    return request({
        url: "user",
        method: "delete",
        data: query
    });
}
