var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.VUETIFY_BREAKPOINT.mobile),expression:"!VUETIFY_BREAKPOINT.mobile"}],staticClass:"custom-menu"},[_vm._l((_vm.items),function(item,index){return (item.active == 1)?_c('v-menu',{key:index,staticClass:"custom-menu",attrs:{"offset-y":"","open-on-hover":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"border-radius":"0"},attrs:{"color":"default","dark":""},on:{"click":function($event){return _vm.redirect(item)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item.text)+" ")])]}}],null,true)},[(item.children)?_c('v-list',{staticClass:"pl-0 pr-0 pt-0 pb-0",staticStyle:{"text-align":"left"}},_vm._l((item.children),function(submenu,subindex){return (submenu.active == 1)?_c('v-btn',{key:subindex,staticStyle:{"border-radius":"0px"},attrs:{"block":""},on:{"click":function($event){return _vm.redirect(submenu)}}},[_vm._v(" "+_vm._s(submenu.text)+" ")]):_vm._e()}),1):_vm._e()],1):_vm._e()}),(_vm.isHomePage)?_c('HomeProfile'):_vm._e(),(_vm.isAlertPage)?_c('AlertProfile'):_vm._e(),(_vm.isHomePage)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"display":"block","float":"right"},attrs:{"id":"homeTutorialIcon","dark":"","color":"primary"},on:{"click":_vm.startTutorial}},'v-icon',attrs,false),on),[_vm._v("mdi-school")])]}}],null,false,2410171345)},[_c('span',[_vm._v("Click to start tutorial")])]):_vm._e(),(_vm.isAlertBrowserPage)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"display":"block","float":"right"},attrs:{"dark":"","color":"primary"},on:{"click":_vm.startTutorial}},'v-icon',attrs,false),on),[_vm._v("mdi-school")])]}}],null,false,144527426)},[_c('span',[_vm._v("Click to start tutorial")])]):_vm._e(),(_vm.isSearchPage)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"display":"block","float":"right"},attrs:{"dark":"","color":"primary"},on:{"click":_vm.startTutorial}},'v-icon',attrs,false),on),[_vm._v("mdi-school")])]}}],null,false,144527426)},[_c('span',[_vm._v("Click to start tutorial")])]):_vm._e(),(_vm.isLpPage && (_vm.lpModule === 0 || _vm.lpModule === 1))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"display":"block","float":"right"},attrs:{"dark":"","color":"primary"},on:{"click":_vm.startTutorial}},'v-icon',attrs,false),on),[_vm._v("mdi-school")])]}}],null,false,144527426)},[_c('span',[_vm._v("Click to start tutorial")])]):_vm._e(),(_vm.isIBPage)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"display":"block","float":"right"},attrs:{"dark":"","color":"primary"},on:{"click":_vm.startTutorial}},'v-icon',attrs,false),on),[_vm._v("mdi-school")])]}}],null,false,144527426)},[_c('span',[_vm._v("Click to start tutorial")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }