import { getTransaction } from "@services/search/transaction";

const state = {
    csvHeader: {
        login: "Login",
        book: "Book",
        amount: "Amount",
        group: "Group"
    },
    filterOrderBy: [
        "server_name",
        "login",
        "ticket",
        "open_time",
        "amount",
        "type",
    ],
    headers: [
        { text: "Server", value: "server_name" , align: "left", width: 100},
        { text: "Login", value: "login" , align: "left", width: 100},
        { text: "Ticket", value: "ticket" , align: "left", width: 120},
        { text: "Open Time", value: "open_time" , align: "left", width: 170},
        { text: "Amount", value: "amount" , align: "right", width: 120},
        { text: "Type", value: "type", align: "left", width: 100 }
    ],
    loading: false,
    btnLoading: false,
    tradesList: [],
    dataAmount: 0
};

const actions = {
    getTransactionAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        commit("UPDATE_BTN_LOADING", true);
        commit("UPDATE_TRANSACTION_HISTORY", []);
        getTransaction(params).then(res => {
            const data = res.data.data;
            data.map((item)=>{
                item.amount = item.amount?.toFixed(2)
            })
            // console.log(data);

            commit("UPDATE_TRANSACTION_HISTORY", data);
            commit("UPDATE_AMOUNT", res.data.count);
            commit("UPDATE_LOADING", false);
            commit("UPDATE_BTN_LOADING", false);
        })
        .catch((e) => {
            console.log(e);
            commit("UPDATE_TRANSACTION_HISTORY", []);
            commit("UPDATE_AMOUNT", 0);
            commit("UPDATE_LOADING", false);
            commit("UPDATE_BTN_LOADING", false);
        });
    },
    requestTransactionAction({ commit }, params) {
        commit("UPDATE_BTN_LOADING", true);
        getTransaction(params).then(res => {
            let snackbar = {
                message: res.data.msg,
                color: "green",
                btnName: "Close",
                status: true,
                timeout: 2000,
            };
            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            commit("UPDATE_BTN_LOADING", false);
        })
        .catch((e) => {
            console.log(e);
            let snackbar = {
                message: "Fail to request csv",
                color: "red",
                btnName: "Close",
                status: true,
                timeout: 2000,
            };
            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            commit("UPDATE_BTN_LOADING", false);
        });
    },
};

const mutations = {
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_BTN_LOADING(state, data) {
        state.btnLoading = data;
    },
    UPDATE_TRANSACTION_HISTORY(state, data) {
        state.tradesList = data;
    },
    UPDATE_AMOUNT(state, data) {
        state.dataAmount = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
