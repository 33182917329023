import request from "@utils/request";

export function getPNLSummary(query) {
    return request({
        url: 'statistic/pnl-summary',
        method: 'post',
        data: query
    })
}

