import {
    getPageProfile,
    postPageProfile,
    putPageProfile,
    deletePageProfile,
} from "@services/page-profile";
import { getPNLSummary } from "@services/pnlSummary";

const state = {
    socketUrl: document.querySelector("body").getAttribute("socketurl")
        ? document.querySelector("body").getAttribute("socketurl") +
          "/publisher-user-svc"
        : process.env.VUE_APP_SOCKET_IB,
    detailSocketUrl: document.querySelector("body").getAttribute("socketurl")
        ? document.querySelector("body").getAttribute("socketurl") +
          "/publisher-book-svc"
        : process.env.VUE_APP_SOCKET_BOOK,
    mainBookTitle: { unrealized: 0, realized: 0, dailyNet: 0 },
    pkData: [],
    pkHeaders: [
        { text: "Symbol", value: "symbol", align: "start" },
        { text: "Long", value: "longPositions", align: "end" },
        { text: "Short", value: "shortPositions", align: "end" },
        { text: "Net Lot", value: "net", align: "end" },
        { text: "Daily Net", value: "dailyNet", align: "end" },
        {
            text: "Unrealized (EOD)",
            value: "floatingProfitEOD",
            align: "end",
        },
        {
            text: "Realized (EOD)",
            value: "closedProfitEOD",
            align: "end",
        },
        {
            text: "Unrealized (MT)",
            value: "floatingProfit",
            align: "end",
        },
        {
            text: "Realized (MT)",
            value: "closedProfit",
            align: "end",
        },
        {
            text: "Long VWAP",
            value: "weightedAvgLongPrice",
            align: "end",
        },
        {
            text: "Short VWAP",
            value: "weightedAvgShortPrice",
            align: "end",
        },
    ],
    subTableHeader: [
        { text: "Login", value: "login", align: "start" },
        { text: "Long", value: "longPositions", align: "end" },
        { text: "Short", value: "shortPositions", align: "end" },
        { text: "Net Lot", value: "netPositions", align: "end" },
        { text: "Daily Net", value: "dailyNet", align: "end" },
        {
            text: "Unrealized (EOD)",
            value: "floatingProfitEOD",
            align: "end",
        },
        {
            text: "Realized (EOD)",
            value: "closedProfitEOD",
            align: "end",
        },
        {
            text: "Unrealized (MT)",
            value: "floatingProfit",
            align: "end",
        },
        {
            text: "Realized (MT)",
            value: "closedProfit",
            align: "end",
        },
        {
            text: "Long VWAP",
            value: "weightedAvgLongPrice",
            align: "end",
        },
        {
            text: "Short VWAP",
            value: "weightedAvgShortPrice",
            align: "end",
        },
    ],
    exposureData: null,

    allProfile: [],
    selectedProfile: {},
    editingDialog: null,
    updated: false,
    isBtnDisabled: false,
    dialogLoading: false,
    PNLSummaryData: [],
    loading: false,
    tableLoading: false,
    symbolDP: [],
    startTutorial: false,
    addedStatus: false,
    profile_name_input: "",
};

const actions = {
    getProfileAction({ commit }, data) {
        // commit("UPDATE_BTN_STATUS", true);
        getPageProfile(data).then((res) => {
            if (res.data.length == 0) {
                commit("UPDATE_ALL_PROFILE", [
                    {
                        id: 0,
                        text: "Default",
                        params: { users: [], sales: [] },
                    },
                ]);

                commit("UPDATE_SELECTED_PROFILE", {
                    id: 0,
                    text: "Default",
                    params: { users: [], sales: [] },
                });
            } else {
                const returnedProfileList = res.data.map((profile) => {
                    return {
                        id: profile.id,
                        text: profile.profile_name,
                        params: JSON.parse(profile.value),
                    };
                });
                commit("UPDATE_ALL_PROFILE", returnedProfileList);

                const ibMonitorID = parseInt(
                    localStorage.getItem("ibMonitorID")
                );
                if (!!state.addedStatus) {
                    commit(
                        "UPDATE_SELECTED_PROFILE",
                        returnedProfileList.find(
                            (item) => item.text === state.profile_name_input
                        ) || returnedProfileList[0]
                    );
                    commit("UPDATE_ADDED_STATUS", false);
                } else {
                    if (!!ibMonitorID) {
                        if (
                            returnedProfileList.find(
                                (item) => item.id === ibMonitorID
                            )
                        ) {
                            commit(
                                "UPDATE_SELECTED_PROFILE",
                                returnedProfileList.find(
                                    (item) => item.id === ibMonitorID
                                )
                            );
                        } else {
                            commit(
                                "UPDATE_SELECTED_PROFILE",
                                returnedProfileList[0]
                            );
                        }
                    } else {
                        commit(
                            "UPDATE_SELECTED_PROFILE",
                            returnedProfileList[0]
                        );
                    }
                }
            }
            commit("UPDATE_LOADING", false);
        });
    },
    putProfileAction({ commit }, data) {
        commit("UPDATE_DIALOG_LOADING", true);
        commit("UPDATE_LOADING", true);
        putPageProfile(data)
            .then(() => {
                commit("CHANGE_UPDATE_STATUS", true);
                commit("UPDATE_DIALOG_LOADING", false);
            })
            .catch(() => {
                commit("UPDATE_DIALOG_LOADING", false);
                commit("UPDATE_LOADING", false);
            });
    },
    postProfileAction({ commit }, data) {
        commit("UPDATE_LOADING", true);
        commit("UPDATE_DIALOG_LOADING", true);
        commit("UPDATE_NEW_PROFILE_NAME_INPUT", data.profile_name);
        postPageProfile(data)
            .then(() => {
                commit("CHANGE_UPDATE_STATUS", true);
                commit("UPDATE_DIALOG_LOADING", false);
                commit("UPDATE_ADDED_STATUS", true);
            })
            .catch(() => {
                commit("UPDATE_DIALOG_LOADING", false);
                commit("UPDATE_LOADING", false);
                commit("UPDATE_ADDED_STATUS", false);
            });
    },
    deleteProfileAction({ commit, state }, data) {
        commit("UPDATE_LOADING", true);
        commit("UPDATE_DIALOG_LOADING", true);
        deletePageProfile(data)
            .then(() => {
                commit("CHANGE_UPDATE_STATUS", true);
                commit("UPDATE_DIALOG_LOADING", false);
            })
            .catch(() => {
                commit("UPDATE_DIALOG_LOADING", false);
                commit("UPDATE_LOADING", false);
            });
    },
    getPNLSummaryAction({ commit }, data) {
        commit("UPDATE_TABLE_LOADING", true);
        commit("UPDATE_PNL_SUMMARY_DATA", []);
        getPNLSummary(data).then((res) => {
            res.data.forEach((item) => {
                item.realized_eod = item.realized_eod.toFixed(2);
                item.realized_mt4 = item.realized_mt4.toFixed(2);
            });
            commit("UPDATE_PNL_SUMMARY_DATA", res.data);
            commit("UPDATE_TABLE_LOADING", false);
        });
    },
};

const mutations = {
    UPDATE_MAIN_BOOK_TITLE(state, data) {
        state.mainBookTitle = data;
    },
    UPDATE_EXPOSURE_DATA(state, data) {
        state.exposureData = data;
    },
    UPDATE_IB_TUTORIAL_STATUS(state, data) {
        state.startTutorial = data;
    },
    UPDATE_PNL_SUMMARY_DATA(state, data) {
        state.PNLSummaryData = data;
    },
    UPDATE_SELECTED_PROFILE(state, data) {
        if (data !== null && localStorage.getItem("ibMonitorID") !== data.id) {
            localStorage.setItem("ibMonitorID", data.id);
        }
        state.selectedProfile = data;
    },
    UPDATE_ALL_PROFILE(state, data) {
        state.allProfile = data;
    },

    CHANGE_UPDATE_STATUS(state, data) {
        state.updated = data;
    },
    UPDATE_BTN_STATUS(state, data) {
        state.isBtnDisabled = data;
    },
    UPDATE_DIALOG_LOADING(state, data) {
        state.dialogLoading = data;
    },
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_TABLE_LOADING(state, data) {
        state.tableLoading = data;
    },
    UPDATE_SYMBOL_DP(state, data) {
        state.symbolDP = data;
    },
    UPDATE_ADDED_STATUS(state, data) {
        state.addedStatus = data;
    },
    UPDATE_NEW_PROFILE_NAME_INPUT(state, data) {
        state.profile_name_input = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
