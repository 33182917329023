import "@plugins/axios";
import store from "@store/store";

export const compareVersion = (newVersion, oldVersion) => {
  const oldArr = oldVersion.split('.')
  const newArr = newVersion.split('.')
  const oldLen = oldArr.length
  const newLen = newArr.length
  const minLen = Math.min(oldLen, newLen)
  let i = 0
  for (; i < minLen; i++) {
    const oldVal = parseInt(oldArr[i])
    const newVal = parseInt(newArr[i])
    if (newVal > oldVal) {
      return 1
    } else if (newVal < oldVal) {
      return -1
    }
  }
  if (newLen > oldLen) {
    for (let j = 0; j < newLen; j++) {
      if (parseInt(newArr[j]) !== 0) {
        return 1
      }
    }
  } else if (newLen < oldLen) {
    for (let j = 0; j < oldLen; j++) {
      if (parseInt(oldArr[j]) !== 0) {
        return -1
      }
    }
  }
  return 0
}

export const checkVersion = () => {
  axios
    .get("version.json", { headers: { "Cache-Control": "no-cache" } })
    .then((res) => {
      // 最新版本
      const latestVersion = res.data.version;

      // 当前版本
      const clientVersion = localStorage.getItem("version");
      if (clientVersion === 'null' || !clientVersion) {
        localStorage.setItem("version", latestVersion);
        return;
      }
      const compareRes = compareVersion(latestVersion, clientVersion)
      store.dispatch('setVersionFlag', compareRes);
    });
}