import {
    getBroker,
    postBroker,
    putBroker,
    deleteBroker,
} from "@services/broker";

const state = {
    loading: false,
    brokerData: [],
    brokerHeader: [
        { text: "ID", value: "id", align: "left" },
        { text: "Broker Name", value: "broker_name", align: "left" },
        { text: "Description", value: "description", align: "left" },
        { text: "Created At", value: "created_at", align: " d-none" },
        { text: "Updated At", value: "updated_at", align: " d-none" },
        { text: "Actions", value: "action", align: "left" },
    ],
    updated: false,
    returnMsg: "",
    csvFields: {
        ID: "id",
        "Broker Name": "broker_name",
        Description: "description",
    },
};

const actions = {
    /**
     * Action to get brokers from api
     *
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  params  [params description]
     * @return  {[type]}          [return description]
     */
    getBrokerAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        getBroker().then((res) => {
            commit("UPDATE_BROKER", res.data);
            commit("UPDATE_LOADING", false);
            commit("UPDATE_STATUS", false);
        });
    },
    /**
     * Create new broker
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  params  [params description]
     *
     * @return  {[type]}          [return description]
     */
    postBrokerAction({ commit, dispatch }, params) {
        commit("UPDATE_LOADING", true);
        let snackbar = {
            message:
                "Adding a book normally takes about 10 seconds, please wait for response..... ",
            color: "orange",
            btnName: "Close",
            status: true,
            timeout: 15000,
        };
        commit("UPDATE_SNACKBAR", snackbar, { root: true });

        postBroker(params)
            .then((res) => {
                commit("UPDATE_LOADING", false);
                commit("UPDATE_STATUS", true);
                commit("UPDATE_MSG", res.data.msg);
            })
            .catch(({ response }) => {
                if (response.status == 409) {
                    let snackbar = {
                        message:
                            response.data.msg + ", please choose another name",
                        color: "red",
                        btnName: "Close",
                        status: true,
                        timeout: 3000,
                    };
                    commit("UPDATE_SNACKBAR", snackbar, { root: true });
                    commit("UPDATE_LOADING", false);
                }
            });
    },
    /**
     * Update broker info
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  params  [params description]
     * @return  {[type]}          [return description]
     */
    putBrokerAction({ commit, dispatch }, params) {
        commit("UPDATE_LOADING", true);
        putBroker(params)
            .then((res) => {
                commit("UPDATE_STATUS", true);
                commit("UPDATE_MSG", res.data.msg);
                commit("UPDATE_LOADING", false);
            })
            .catch(() => {
                commit("UPDATE_LOADING", false);
            });
    },
    deleteBrokerAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        deleteBroker(params)
            .then((res) => {
                commit("UPDATE_LOADING", false);
                commit("UPDATE_MSG", res.data.msg);
                commit("UPDATE_STATUS", true);
            })
            .catch(({ response }) => {
                if (response.status == 406) {
                    let snackbar = {
                        message:
                            response.data.msg +
                            ", please check the book table before deleting this broker",
                        color: "red",
                        btnName: "Close",
                        status: true,
                        timeout: 4000,
                    };
                    commit("UPDATE_SNACKBAR", snackbar, { root: true });
                    commit("UPDATE_LOADING", false);
                }
            });
    },
};

const mutations = {
    /**
     * Update brokers
     *
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_BROKER(state, data) {
        state.brokerData = data;
    },
    /**
     * Update loading
     *
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_STATUS(state, data) {
        state.updated = data;
    },
    UPDATE_MSG(state, data) {
        state.returnMsg = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
