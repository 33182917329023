import { getPermission } from "@services/account/permission";
import {
    getUsers,
    postUsers,
    putUsers,
    deleteUsers,
} from "@services/account/user";
import { logUserOut } from "@services/account/auth";
import { getProfile, putProfile } from "@services/page-profile";

const state = {
    listUser: [],
    loading: false,
    uploaded: false,
    headers: [
        { text: "Email", value: "email", fixed: true },
        { text: "Username", value: "username" },
        { text: "Mobile", value: "mobile" },
        { text: "Last Login", value: "last_login" },
        { text: "Role", value: "role" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Active", value: "is_active" },
        { text: "Actions", value: "actions", sortable: false, fixed: true },
    ],
    permissionData: [],
    permissionList: [],
    profileData: [],
};

const actions = {
    /**
     * User api to load user
     * @return  {[type]}  [return description]
     */
    getUsersAction({ commit, state }) {
        getUsers().then((res) => {
            const userList = res.data;
            userList.map((userData) => {
                userData.role = state.permissionData.filter(
                    (item) => item.id == userData.permission_id
                )[0]?.permission_name
                userData.permission = state.permissionData.filter(
                    (item) => item.id == userData.permission_id
                )[0]?.permission
            });
            commit("UPDATE_LIST_USER", userList);
            commit("UPDATE_LOADING", false);
        });
    },
    /**
     * User create via API
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  data    [data description]
     * @return  {[type]}          [return description]
     */
    postUsersAction({ commit, dispatch }, dialog) {
        commit("UPDATE_LOADING", true);
        const userEmailList = state.listUser.map((user) => user.email);
        const userNameList = state.listUser.map((user) => user.username);

        if (
            userEmailList.includes(dialog.email) ||
            userNameList.includes(dialog.username)
        ) {
            let snackbar = {
                message: "User name or Email already exist.",
                color: "red",
                btnName: "Close",
                status: true,
                timeout: 3000,
            };
            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            commit("UPDATE_LOADING", false);
        } else {
            let params = {
                email: dialog.email,
                password: dialog.password,
                first_name: dialog.firstname,
                last_name: dialog.lastname,
                username: dialog.username,
                permission_id: dialog.permission_id,
                mobile: dialog.mobile,
                is_active: dialog.isActive,
            };

            postUsers(params).then((res) => {
                let snackbar = {
                    message: "Create User Successfully",
                    color: "green",
                    btnName: "Close",
                    status: true,
                    timeout: 2000,
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
                commit("UPDATE_UPLOADED", true);
            });
        }
    },
    /**
     * Update existing user
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  dialog    [dialog description]
     * @return  {[type]}          [return description]
     */
    putUsersAction({ commit, dispatch }, dialog) {
        commit("UPDATE_LOADING", true);
        const userNameList = state.listUser.map((user) => user.username);
        const userNameToEditingID = state.listUser.find(
            (item) => item.id === dialog.id
        ).username;

        if (
            userNameToEditingID !== dialog.username &&
            userNameList.includes(dialog.username)
        ) {
            let snackbar = {
                message: "User name already exist.",
                color: "red",
                btnName: "Close",
                status: true,
                timeout: 3000,
            };
            commit("UPDATE_SNACKBAR", snackbar, { root: true });
            commit("UPDATE_LOADING", false);
        } else {
            let params = {
                id: dialog.id,
                email: dialog.email,
                password: dialog.password,
                first_name: dialog.firstname,
                last_name: dialog.lastname,
                username: dialog.username,
                permission_id: dialog.permission_id,
                mobile: dialog.mobile,
                is_active: dialog.isActive,
            };
            putUsers([params])
                .then(() => {
                    let snackbar = {
                        message: "Update User Successfully",
                        color: "green",
                        btnName: "Close",
                        status: true,
                        timeout: 2000,
                    };
                    commit("UPDATE_SNACKBAR", snackbar, { root: true });
                    commit("UPDATE_UPLOADED", true);
                })
                .catch(() => {
                    commit("UPDATE_LOADING", false);
                });
        }
    },
    deleteUserAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        deleteUsers(params).then((res) => {
            commit("UPDATE_UPLOADED", true);
        });
    },
    /**
     * Update account status
     * @param   {[type]}  status  [status description]
     * @return  {[type]}          [return description]
     */
    changeUserStatusAction({ commit }, item) {
        commit("CHANGE_USER_STATUS", item);
    },
    /**
     * Load permission
     * @return  {[type]}  [return description]
     */
    getPermissionAction({ commit }) {
        commit("UPDATE_LOADING", true);
        getPermission().then((res) => {
            commit("UPDATE_PERMISSION_DATA", res.data);
            localStorage.setItem("permissionData", JSON.stringify(res.data));
            let permissionList = res.data.map((i) => i.permission_name);
            commit("UPDATE_PERMISSION_LIST", permissionList);
        });
    },
    getProfileAction({ commit }) {
        getProfile().then((res) => {
            commit("UPDATE_PROFILE_DATA", res.data);
        });
    },
    putProfileAction({ commit, dispatch }, params) {
        commit("UPDATE_LOADING", true);

        putProfile(params)
            .then(() => {
                let snackbar = {
                    message: "Update Profiles Successfully",
                    color: "green",
                    btnName: "Close",
                    status: true,
                    timeout: 2000,
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
                commit("UPDATE_UPLOADED", true);
            })
            .catch(() => {
                commit("UPDATE_LOADING", false);
            });
    },
    batchUpdateAction({ commit, state }, data) {
        let output = [];
        data.selectedUsers.map((id) => {
            const foundUser = state.listUser.filter(
                (user) => user.id === id
            )[0];
            let params = {
                id: id,
                email: foundUser.email,
                password: null,
                first_name: foundUser.first_name,
                last_name: foundUser.last_name,
                username: foundUser.username,
                permission_id: data.permission_id,
                mobile: foundUser.mobile,
                is_active: foundUser.is_active,
            };
            output.push(params);
        });
        commit("UPDATE_LOADING", true);
        putUsers(output)
            .then(() => {
                let snackbar = {
                    message: "Update User Successfully",
                    color: "green",
                    btnName: "Close",
                    status: true,
                    timeout: 2000,
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
                commit("UPDATE_UPLOADED", true);
            })
            .catch(() => {
                commit("UPDATE_LOADING", false);
            });
    },
    forceLogoutAction({ commit, state }, data) {
        commit("UPDATE_LOADING", true);
        logUserOut(data)
            .then((res) => {
                let snackbar = {
                    message: "Log User Out Successfully",
                    color: "green",
                    btnName: "Close",
                    status: true,
                    timeout: 2000,
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
                commit("UPDATE_UPLOADED", true);
            })
            .catch(() => {
                commit("UPDATE_LOADING", false);
            });
    },
};

const mutations = {
    /**
     * Update permission data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_PERMISSION_DATA(state, data) {
        state.permissionData = data;
    },
    UPDATE_PROFILE_DATA(state, data) {
        state.profileData = data;
    },
    /**
     * Update permission list
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_PERMISSION_LIST(state, data) {
        state.permissionList = data;
    },
    /**
     * Update List User
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LIST_USER(state, data) {
        state.listUser = data;
    },
    UPDATE_DIALOG(state, data) {},
    /**
     * Update loading status
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    CHANGE_USER_STATUS(state, data) {
        const found = state.listUser.find((item) => item.id == data.id);
        found.is_active = data.is_active;
    },
    UPDATE_UPLOADED(state, data) {
        state.uploaded = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
