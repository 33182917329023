import { getDailyLoser } from "@services/alert/daily-loser";
import common from "@assets/js/common.js";

const state = {
    autoRefreshTimeOffset: 1,
    loading: false,
    alertData: [],
    alertHeader: [
        { text: "Login", value: "login", align: "left" },
        { text: "Name", value: "name", align: "left" },
        { text: "Books", value: "books", align: "left" },
        {
            text: "Closed Profit EOD",
            value: "closed_profit_eod",
            align: "right",
        },
    ],
    alertDataAmount: 0,
    csvHeader: {
        "Server Name": "server_name",
        Login: "login",
        Books: "books",
        "Closed Profit EOD": "closed_profit_eod",
        "Closed Profit MT": "closed_profit",
    },
    selectedDate: null,
};

const actions = {
    getDailyLoserAction({ commit }, data) {
        if (common.getMT4TimeString().split(" ")[0] !== state.selectedDate) {
            commit("UPDATE_LOADING", true);
            getDailyLoser(data).then((res) => {
                res.data.data.forEach((item) => {
                    item["books"] = item.books.replace(/[[\]]/g, "");
                });
                commit("UPDATE_ALERT_DATA", res.data.data);
                commit("UPDATE_AMOUNT", res.data.count);
                commit("UPDATE_LOADING", false);
            });
        }
    },
    processDailyLoser({ commit }, data) {
        if (common.getMT4TimeString().split(" ")[0] === state.selectedDate) {
            commit("UPDATE_LOADING", true);
            let result = [];
            for (let item in data) {
                if (item.includes("--loser")) {
                    let temp = data[item];
                    temp.books = temp.books.join(",");
                    temp.server_name = temp.user.split(":")[1];
                    temp.login = temp.user.split(":")[2];
                    temp.name = item.replaceAll('--loser','').split(":").slice(3).join(" ");
                    temp.closed_profit_eod = temp.profitClosed;
                    temp.closed_profit = temp.profitClosedMT4;
                    result.push(temp);
                }
            }
            commit("UPDATE_ALERT_DATA", result);
            commit("UPDATE_AMOUNT", result.length);
            commit("UPDATE_LOADING", false);
        }
    },
};

const mutations = {
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
    UPDATE_SELECTED_DATE(state, data) {
        state.selectedDate = data;
    },
    UPDATE_AMOUNT(state, data) {
        state.alertDataAmount = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
