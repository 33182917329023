import { getConfig } from "@services/alert/config";

const state = {
    loading: false,
    monitorData: [],
    monitorHeader: [
        { text: "Book", value: "book", align: "left" },
        { text: "Bymbol", value: "symbol", align: "left" },
        {
            text: "Floating Profit EOD",
            value: "floatingProfitEod",
            align: "left",
        },
        { text: "Net Volume", value: "netVolume", align: "left" },
        { text: "Net Volume TH", value: "netVolumeThreshold", align: "left" },
        { text: "Profit Threshold", value: "profitThreshold", align: "left" },
        { text: "Trigger Time", value: "trigger_time", align: "left" },
    ],
    alertData: [],
    alertHeader: [
        { text: "Book", value: "book", align: "left" },
        { text: "Bymbol", value: "symbol", align: "left" },
        { text: "Net Volume", value: "net_volume", align: "left" },
        { text: "Net Volume TH", value: "net_volume_threshold", align: "left" },
        {
            text: "Floating Profit EOD",
            value: "floating_profit_eod",
            align: "left",
        },
        { text: "Profit Threshold", value: "profit_threshold", align: "left" },
        { text: "Alert Status", value: "alert_status", align: "left" },
        { text: "Trigger Time", value: "trigger_time", align: "left" },
    ],
    yValue: [],
    // yValue: [200, 82, 27, -97, 7, 20, 150, 10, 87, 15, 13, 10, 9, 10, 15, 14, 10, 50, 7, 15, 20, 22, 12, 29, 7, 101],
    yName: [],
    // yName: ['IBM', 'WFC', 'TSLA', 'AAPL', 'DOW', 'META', 'NKLA', 'GM', 'CSCO', 'GILD', 'DIS', 'KHC', 'BABA', 'QS', 'BAC', 'MMM', 'EBAY', 'PLTR', 'SQ', 'QCOM', 'AMZN', 'C', 'INTC', 'U', 'NFLX', 'NVDA'],
    currentBook: "B",
    profitThreshold: 0,
    volumeThreshold: 0,
};

const actions = {
    getMandateConfigAction({ commit, state }) {
        getConfig("mandate").then((res) => {
            if (res.data.length) {
                state.profitThreshold = res.data[0].profit_threshold;
                state.volumeThreshold = res.data[0].volume_threshold;
            }
        });
    },
    processChartMandate({ commit, state }, data) {
        let filteredAlertData = Object.keys(data).map((k) => {
            return data[k];
        });

        let tempData = filteredAlertData.filter(
            (item) => item.book === state.currentBook
        );
        tempData = tempData.sort((a, b) =>
            a.net_volume > b.net_volume
                ? 1
                : b.net_volume > a.net_volume
                ? -1
                : 0
        );
        let yName = tempData.map((item) => item.symbol);
        let yValue = tempData.map((item) => {
            return { volume: item.netVolume, pnl: item.floatingProfitEod };
        });
        commit("UPDATE_Y_VALUE", yValue);
        commit("UPDATE_Y_NAME", yName);
    },
};

const mutations = {
    UPDATE_Y_NAME(state, data) {
        state.yName = data;
    },
    UPDATE_Y_VALUE(state, data) {
        state.yValue = data;
    },
    /**
     * Update monitor data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_MONITOR_DATA(state, data) {
        state.monitorData = data;
    },
    /**
     * Update alert data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    /**
     * Update current book
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_CURRENT_BOOK(state, data) {
        state.currentBook = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
