import { getProfitLoss } from "@services/search/profit-loss";

const state = {
    csvHeader: {
        login: "Login",
        book: "Book",
        amount: "Amount",
        group: "Group"
    },
    filterOrderBy: [
        "login",
        "open",
        "closed",
        "net",
        "deposit",
        "book",
        "group",
        "CCY"
    ],
    headers: [
        { text: "Login", value: "login",align: "left" },
        { text: "Floating", value: "floating",align: "right" },
        { text: "Closed", value: "closed",align: "right" },
        { text: "Net", value: "net",align: "right" },
        { text: "Deposit", value: "deposit",align: "right" },
        { text: "Book", value: "book",align: "left" },
        { text: "Group", value: "group",align: "left" },
        { text: "CCY", value: "CCY",align: "right" }
    ],
    filterShowResult: [100, 200],
    loading: false,
    tradesList: []
};

const actions = {
    getProfitLossAction({ commit, state }, params) {
        commit("UPDATE_LOADING", true);
        commit("UPDATE_PROFIT_LOSS_HISTORY", []);
        getProfitLoss(params).then(res => {
            const data = res.data;

            commit("UPDATE_PROFIT_LOSS_HISTORY", data);
            commit("UPDATE_LOADING", false);
        });
    }
};

const mutations = {
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_PROFIT_LOSS_HISTORY(state, data) {
        state.tradesList = data;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
