import request from "@utils/request";

export function getTick(query) {
    return request({
        url: "alert/data/tick",
        method: "get",
        params: query,
    });
}

export function postTick(query) {
    return request({
        url: "alert/data/tick",
        method: "post",
        data: query,
    });
}
