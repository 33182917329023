import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import Home from "@store/modules/home";
import IB from "@store/modules/ib";
import UserManagement from "@store/modules/account/user";
import Alert from "@store/modules/alert/index";
import LoginSummaryDialog from "@store/modules/components/login-summary-dialog";
import SettingsBooks from "@store/modules/settings/books";
import SettingsRouting from "@store/modules/settings/routing";
import SettingsBroker from "@store/modules/settings/broker";
import SettingsServer from "@store/modules/settings/server";
import LargeVolume from "@store/modules/alert/large-volume";
import LargeVolumeUSD from "@store/modules/alert/large-volume-usd";
import ProfitTaker from "@store/modules/alert/profit-taker";
import FastTrade from "@store/modules/alert/fast-trade";
import SameDirection from "@store/modules/alert/same-direction";
import DepositWithdrawal from "@store/modules/alert/deposit-withdrawal";
import LargeExposure from "@store/modules/alert/large-exposure";
import LargeExposureVolume from "@store/modules/alert/large-exposure-volume";
import WeightedVolume from "@store/modules/alert/weighted-volume";
import LockingPosition from "@store/modules/alert/locking-position";
import Mandate from "@store/modules/alert/mandate";
import DailyWinner from "@store/modules/alert/daily-winner";
import DailyLoser from "@store/modules/alert/daily-loser";
import TOC from "@store/modules/alert/trade-on-credit/index";
import WatchList from "@store/modules/alert/watch-list";
import K8S from "@store/modules/k8s";
import LP from "@store/modules/lp";
import LoginSummary from "@store/modules/statistic/login-summary";
import Auth from "@store/modules/account/signin";
import TickSpread from "@store/modules/search/tick-spread";
import Config from "@store/modules/settings/alert";
import SitePermissionConfig from "@store/modules/account/permission";
import RawHistory from "@store/modules/search/rawHistory";
import DownloadStatus from "@store/modules/search/downloadStatus";
import ProfitLossSearch from "@store/modules/search/profit-loss";
import VolumeSearch from "@store/modules/search/volume";
import Transaction from "@store/modules/search/transaction";
import Security from "@store/modules/account/security";
import Tick from "@store/modules/alert/tick";
import CopyTrade from "@store/modules/copy-trade";
import Notification from "@store/modules/settings/notification";
import SettingsCopyTrade from "@store/modules/settings/copy-trade";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        IB: IB,
        Notification:Notification,
        CopyTrade: CopyTrade,
        SettingsCopyTrade: SettingsCopyTrade,
        SameDirection: SameDirection,
        TickSpread: TickSpread,
        DepositWithdrawal: DepositWithdrawal,
        ProfitLossSearch: ProfitLossSearch,
        VolumeSearch: VolumeSearch,
        Auth: Auth,
        LoginSummary: LoginSummary,
        LP: LP,
        K8S: K8S,
        LockingPosition: LockingPosition,
        Mandate: Mandate,
        FastTrade: FastTrade,
        WeightedVolume: WeightedVolume,
        LargeExposure: LargeExposure,
        ProfitTaker: ProfitTaker,
        LargeVolume: LargeVolume,
        LargeVolumeUSD: LargeVolumeUSD,
        TOC: TOC,
        WatchList,
        SettingsServer: SettingsServer,
        SettingsBroker: SettingsBroker,
        SettingsBooks: SettingsBooks,
        SettingsRouting: SettingsRouting,
        Home: Home,
        LoginSummaryDialog: LoginSummaryDialog,
        UserManagement: UserManagement,
        Alert: Alert,
        Config: Config,
        RawHistory: RawHistory,
        DownloadStatus: DownloadStatus,
        Transaction: Transaction,
        SitePermissionConfig: SitePermissionConfig,
        Security: Security,
        DailyWinner: DailyWinner,
        DailyLoser: DailyLoser,
        LargeExposureVolume: LargeExposureVolume,
        Tick: Tick,
    },
    state: {
        // alertInterval: 30000,
        isValidJson: false,
        tickConfig: {
            visible: false,
            broker: "",
            name: "",
            search: "",
        },
        alertNotificationSettings: [],
        selectedBroker: "",
        selectedBrokerId: -1,

        disalbeNotificationAlert: [],
        loginBtnLoading: false,
        loginStatus: -1,
        loginMsg: "",
        socket: null,
        connectId: null,
        snackbar: false,
        snackbarbtn: "Close",
        snackbarbtnid: "snackbar",
        snackmsg: "",
        snacktimeout: 2000,
        overlay: false,
        snackclosecolor: "white",
        snackcolor: "",
        brokerId: -1,
        jwtToken: null,
        assignUser: [],
        brokerMap: [
            { broker: "AUGS", broker_id: 1 },
            { broker: "SUPER", broker_id: 2 },
            { broker: "PROSPERO", broker_id: 3 },
            // { "broker": "DEMO", "broker_id": 99 },
        ],
        symbolRules: ["-", "c", "s", "."],
        symbolEndfix: [
            "-s",
            "-e",
            "-r",
            "s",
            "c",
            ".",
            "-i",
            "-k",
            "-u",
            "-h",
            "-y",
            "-p",
            "-std",
            "-ecn",
            "-inx",
            "",
        ],

        alertHeader: {
            big_lot: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Open Time", value: "orderOpenTime", align: "left" },
                { text: "Symbol", value: "symbol", align: "left" },
                { text: "Vol", value: "volume", align: "left" },
                { text: "Ticket", value: "order", align: "left" },
                { text: "Dir", value: "direction", align: "left" },
                { text: "Group", value: "group", align: " d-none" },
                { text: "Msg", value: "message", align: " d-none" },
            ],
            mandate: [
                { text: "Time", value: "time", align: "left" },
                { text: "Book/Group", value: "book", align: "left" },
                { text: "Symbol", value: "symbol", align: "left" },
                { text: "Type", value: "type", align: "left" },
                { text: "Value", value: "currentValue", align: "left" },
                { text: "Threshold", value: "threshold", align: "left" },
                { text: "Msg", value: "message", align: " d-none" },
            ],
            profit_taker: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Symbol", value: "symbol", align: "left" },
                { text: "PNL", value: "profitToday", align: "left" },
                { text: "Msg", value: "message", align: " d-none" },
            ],
            locking_position: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Symbol", value: "symbol", align: "left" },
                {
                    text: "Lock Position",
                    value: "lockingPosition",
                    align: "left",
                },
                { text: "Msg", value: "message", align: " d-none" },
            ],
            trade_on_credit: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Balance", value: "balance", align: "left" },
                { text: "Credit", value: "credit", align: "left" },
                { text: "Equity", value: "equity", align: "left" },
                { text: "Msg", value: "message", align: " d-none" },
            ],
            large_exposure: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Symbol", value: "symbol", align: "left" },
                { text: "Exposure", value: "exposure", align: "left" },
                { text: "message", value: "message", align: " d-none" },
            ],
            fast_trade: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Symbol", value: "symbol", align: "left" },
                { text: "Volume", value: "volume", align: "left" },
                { text: "Group", value: "group", align: "left" },
                { text: "Ticket", value: "ticket", align: "left" },
                { text: "Message", value: "message", align: " d-none" },
            ],
            watch_list: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Symbol", value: "symbol", align: "left" },
                { text: "Direction", value: "direction", align: "left" },
                { text: "Group", value: "group", align: "left" },
                { text: "Price", value: "price", align: "left" },
                { text: "Ticket", value: "orderTicket", align: "left" },
                { text: "Message", value: "message", align: " d-none" },
            ],
            tick_check: [
                // // { text: "Time", value: "time", align: " d-none"},
                // { text: "Symbol", value: "symbol", align: "left" },
                // { text: "Now(MT4)", value: "now", align: "left" },
                // { text: "Gap", value: "gap", align: "left" },
                // { text: "Last Update Time(MT4)", value: "lastUpdateTime", align: "left" },
                // // { text: "Local Time", value: "local_time", align: " d-none" },
                // { text: "Threshold", value: "threshold", align: "left" },

                { text: "Symbol", value: "symbol", align: "left" },
                { text: "Now(MT4)", value: "now", align: "left" },
                {
                    text: "Last Update Time(MT4)",
                    value: "lastUpdateTime",
                    align: "left",
                },
                { text: "Gap", value: "gap", align: "left" },
                { text: "Threshold", value: "threshold", align: "left" },
                { text: "Server", value: "server", align: "left" },

                // { text: "Symbol", value: "symbol", align: "left" },
                // { text: "Last Update", value: "lastUpdateTime", align: "left" },
                // { text: "Current", value: "now", align: "left" },
                // { text: "Gap", value: "gap", align: "left" },
                // { text: "Message", value: "message", align: "left" },
            ],
            same_direction: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Symbol", value: "symbol", align: "left" },
                { text: "Volume", value: "volume", align: "left" },
                { text: "Ticket", value: "order", align: "left" },
                { text: "Event", value: "event", align: "left" },
                { text: "Total Volume", value: "totalVolume", align: "left" },
                { text: "Message", value: "message", align: " d-none" },
            ],
            volume_alert: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Profit", value: "profit", align: "left" },
                { text: "Vol Today", value: "volumeToday", align: "left" },
                { text: "message", value: "message", align: " d-none" },
            ],
            withdraw_deposit: [
                { text: "Time", value: "time", align: "left" },
                { text: "Login", value: "login", align: "left" },
                { text: "Book", value: "book", align: "left" },
                { text: "Order", value: "order", align: "left" },
                { text: "Type", value: "type", align: "left" },
                { text: "Amount", value: "amount", align: "left" },
                {
                    text: "Thredshold Amount",
                    value: "thredshold_amount",
                    align: "left",
                },
                { text: "message", value: "message", align: " d-none" },
            ],
        },
        currentTimeServer: null,
        allowJsonEditor: 0,
        versionFlag: false,
    },
    getters: {
        getCurrentTimeServer(state) {
            return state.currentTimeServer;
        },
    },
    mutations: {
        ...mutations,
        SET_VERSION_FLAG(state, data) {
            state.versionFlag = data;
        },
    },
    actions: {
        setVersionFlag({ commit }, flag) {
            if (flag > 0) {
                commit("SET_VERSION_FLAG", true);
            } else {
                commit("SET_VERSION_FLAG", false);
            }
        },
    },
});

export default store;
