import io from "socket.io-client";
import store from "./store";
import * as CryptoJS from "crypto-js";
import _ from "lodash";
// import { MESSAGE_TYPE } from "vue-baberrage";

const mutations = {
    /**
     * Change snackbar to show message toast
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_SNACKBAR(state, data) {
        state.snackmsg = data.message;
        state.snackcolor = data.color;
        state.snackbarbtn = data.btnName;
        state.snackbar = data.status;
        state.snacktimeout = data.timeout;
    },
    /**
     * Update is valid json value
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  value  [value description]
     * @return  {[type]}         [return description]
     */
    UPDATE_IS_VALID_JSON(state, value) {
        state.isValidJson = value;
    },
    /**
     * Check if data is valid json or not
     * @param   {[type]}  data  [data description]
     * @return  {[type]}        [return description]
     */
    IS_VALID_JSON(state, data) {
        console.log("IS_VALID_JSON: ", data);
        try {
            if (typeof data === "object" && data !== null) {
                state.isValidJson = true;
            } else {
                JSON.parse(data);
                state.isValidJson = true;
            }
        } catch (e) {
            state.isValidJson = false;
        }
    },
    assignAlertHeader (state, layoutTable) {
        if (layoutTable === undefined) return;
        if (layoutTable === null) return;
        for (const alert in state.alertHeader) {
            if (layoutTable.hasOwnProperty(alert)) {
                state.alertHeader[alert] = layoutTable[alert];
            }
        }
    },
    /**
     * 改变alert表格header显示
     * @param  {[type]} state  [description]
     * @param  {[type]} header [description]
     * @param  {[type]} alert  [description]
     * @param  {[type]} status [description]
     * @return {[type]}        [description]
     */
    toggleAlertHeader (state, data) {
        const status = data.status;
        const alert = data.alert;
        const header = data.header;

        for (let i = 0; i < state.alertHeader[alert].length; i++) {
            if (state.alertHeader[alert][i].value == header) {
                state.alertHeader[alert][i].align = status;
                break;
            }
        }
    },
    /**
     * Toggle snack message
     * @param {*} state
     * @param {*} config
     */
    toggleSnackMessage (state, config) {
        state.snackbar = config.visible;
        state.snackcolor = config.color;
        state.snackmsg = config.message;
    },
    dismissSnackbar (state) {
        // console.log("Access dismiss");
        state.snackbar = false;
    }
};
export default mutations;
