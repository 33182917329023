import request from "@utils/request";

export function getRouting(query = {}) {
    return request({
        url: "trades-routing",
        method: "get",
        params: query
    });
}

export function postRouting(query) {
    return request({
        url: "trades-routing",
        method: "post",
        data: query
    });
}

export function putRouting(query) {
    return request({
        url: "trades-routing",
        method: "put",
        data: query
    });
}

export function deleteRouting(query) {
    return request({
        url: "trades-routing",
        method: "delete",
        data: query
    });
}

export function getOrderRoutingMapping(params) {
    return request({
        url: "oz/settings-order-routing-mapping",
        method: "get",
        params
    });
}
