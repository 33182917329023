import common from "@assets/js/common";

const state = {
    loading: false,
    monitorData: [],
    monitorHeader: [
        { text: "Login", value: "login", align: "left" },
        { text: "Name", value: "name", align: "left" },
        { text: "Books", value: "books", align: "left" },
        { text: "Symbol", value: "symbol", align: "left" },
        { text: "Volume", value: "exposureVolume", align: "left" },
    ],
    csvHeader: {
        Login: "login",
        Books: "books",
        Symbol: "symbol",
        Volume: "exposureVolume",
    },
};

const actions = {
    /**
     * Process alert data
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  data    [data description]
     *
     * @return  {[type]}          [return description]
     */
    processLargeExposureVolume({ commit }, data) {
        let result = [];
        for (let item in data) {
            let temp = data[item];
            temp["login"] = item.split(":")[1]+":"+item.split(":")[2];
            temp["symbol"] = item.split(":").slice(-1)[0];
            temp['name'] = item.split(":").slice(3, -1).join(" ");
            temp["trigger_time"] = common.convertUnixtimeToDatetimeString(
                temp.timestamp
            );
            if (temp["books"].length == 2 && temp["books"].includes("STOCKS")) {
                temp["books"] = temp["books"].filter((i) => i !== "STOCKS");
            } else if (temp["books"].length >= 3) {
                temp["books"] = temp["books"].filter(
                    (i) =>
                        i !== "STOCKS" && i !== "EquityAU" && i !== "EquityUS"
                );
            }
            result.push(temp);
        }
        // console.log(result)
        commit("UPDATE_MONITOR_DATA", result);
    },
};

const mutations = {
    /**
     * Update monitor data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_MONITOR_DATA(state, data) {
        state.monitorData = data;
    },
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_MONITOR_HEADER(state, data) {
        state.monitorHeader = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
