import request from "@utils/request";

export function getDownloadStatus (query) {
    return request({
        url: "search/download-status",
        method: "post",
        data: query
    });
}

export function downloadFileUpdate (query) {
    return request({
        url: "search/download-file",
        method: "post",
        data: query
    });
}