import axios from "axios";

export function getLoginTrades(query) {
    const config = {
        params: query,
        headers: {
            Token: localStorage.getItem("token"),
        },
    };

    return axios.get(
        (document.querySelector("body").getAttribute("socketapi") ||
            process.env.VUE_APP_SOCKET_API) +
                "/calculationResult/calculatedTradeByLoginAndSymbol",
        config
    );
}
