import {
    deleteConfig,
    getConfig,
    postConfig,
    putConfig,
} from "@services/notification";

const state = {
    configData: [],
    updated: false,
    loading: true,
    headerOptions: {
        Users: [
            {
                text: "Id",
                value: "id",
            },
            {
                text: "User name",
                value: "user_name",
            },
            {
                text: "Email",
                value: "email",
            },
            {
                text: "SMS",
                value: "sms",
            },
            {
                text: "Enable",
                value: "enable",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        Groups: [
            {
                text: "Id",
                value: "id",
            },
            {
                text: "Group Name",
                value: "group_name",
            },
            {
                text: "Telegram",
                value: "telegram",
            },
            {
                text: "Enable",
                value: "enable",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        Rules: [
            {
                text: "Id",
                value: "id",
            },
            {
                text: "Rule Name",
                value: "rule_name",
            },
            {
                text: "Enable",
                value: "enable",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        "User-Groups": [
            {
                text: "Id",
                value: "id",
            },
            {
                text: "Group Name",
                value: "group_id",
            },
            {
                text: "User Name",
                value: "user_id",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
        "Group-Rules": [
            {
                text: "Id",
                value: "id",
            },
            {
                text: "Group Name",
                value: "group_id",
            },
            {
                text: "Rule Name",
                value: "rule_id",
            },
            {
                text: "Created At",
                value: "created_at",
            },
            {
                text: "Updated At",
                value: "updated_at",
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                fixed: true,
            },
        ],
    },
    optionData: {
        Users: [],
        Groups: [],
        Rules: [],
    },
};

const actions = {
    initialAction({ commit, state }) {
        ["Users", "Groups", "Rules"].map((type) => {
            const typeName = type.toLowerCase().replaceAll("-", "_");
            const params = {
                type: typeName,
            };
            getConfig(params)
                .then((res) => {
                    state.optionData[type] = res.data;
                })
                .catch((e) => {
                    console.log(e);
                    commit("UPDATE_LOADING", false);
                });
        });
    },
    getNotificationDataAction({ commit, state }, data) {
        commit("UPDATE_LOADING", true);
        const typeName = data.toLowerCase().replaceAll("-", "_");
        const params = {
            type: typeName,
        };
        getConfig(params)
            .then((res) => {
                commit("UPDATE_CONFIG_DATA", res.data);
                commit("UPDATE_LOADING", false);
                if (!data.includes("-") && !!res.data) {
                    state.optionData[data] = res.data;
                }
            })
            .catch((e) => {
                console.log(e);
                commit("UPDATE_LOADING", false);
            });
    },
    deleteNotificationDataAction({ commit }, data) {
        commit("CHANGE_UPDATE_STATUS", false);
        commit("UPDATE_LOADING", true);
        const typeName = data["type"].toLowerCase().replaceAll("-", "_");
        const params = {
            type: typeName,
            id: data["id"],
        };
        deleteConfig(params)
            .then((res) => {
                if (!!res.data.msg) {
                    let snackbar = {
                        message: res.data.msg,
                        color: "green",
                        btnName: "Close",
                        status: true,
                        timeout: 3000,
                    };
                    commit("UPDATE_SNACKBAR", snackbar, { root: true });
                }
                commit("CHANGE_UPDATE_STATUS", true);
                commit("UPDATE_LOADING", false);
            })
            .catch(({ response }) => {
                if (!!response.data.msg) {
                    let snackbar = {
                        message: response.data.msg,
                        color: "red",
                        btnName: "Close",
                        status: true,
                        timeout: 3000,
                    };
                    commit("UPDATE_SNACKBAR", snackbar, { root: true });
                }
                commit("UPDATE_LOADING", false);
            });
    },
    addNotificationDataAction({ commit }, data) {
        commit("CHANGE_UPDATE_STATUS", false);
        commit("UPDATE_LOADING", true);

        postConfig(data)
            .then((res) => {
                if (!!res.data.msg) {
                    let snackbar = {
                        message: res.data.msg,
                        color: "green",
                        btnName: "Close",
                        status: true,
                        timeout: 3000,
                    };
                    commit("UPDATE_SNACKBAR", snackbar, { root: true });
                }
                commit("CHANGE_UPDATE_STATUS", true);
                commit("UPDATE_LOADING", false);
            })
            .catch(({ response }) => {
                if (!!response.data.msg) {
                    let snackbar = {
                        message: response.data.msg,
                        color: "red",
                        btnName: "Close",
                        status: true,
                        timeout: 3000,
                    };
                    commit("UPDATE_SNACKBAR", snackbar, { root: true });
                }
                commit("UPDATE_LOADING", false);
            });
    },
    updateNotificationDataAction({ commit }, data) {
            commit("CHANGE_UPDATE_STATUS", false);
            commit("UPDATE_LOADING", true);
            
            putConfig(data)
            .then((res) => {
                console.log(res);
                if (!!res.data.msg) {
                    let snackbar = {
                        message: res.data.msg,
                        color: "green",
                        btnName: "Close",
                        status: true,
                        timeout: 3000,
                    };
                    commit("UPDATE_SNACKBAR", snackbar, { root: true });
                }
                commit("CHANGE_UPDATE_STATUS", true);
                commit("UPDATE_LOADING", false);
            })
            .catch(({ response }) => {
                if (!!response.data.msg) {
                    let snackbar = {
                        message: response.data.msg,
                        color: "red",
                        btnName: "Close",
                        status: true,
                        timeout: 3000,
                    };
                    commit("UPDATE_SNACKBAR", snackbar, { root: true });
                }
                commit("UPDATE_LOADING", false);
            });
    },
};

const mutations = {
    CHANGE_UPDATE_STATUS(state, data) {
        state.updated = data;
    },
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_CONFIG_DATA(state, data) {
        state.configData = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
