import request from "@utils/request";

export function getServer(query) {
    return request({
        url: 'broker-server',
        method: 'get',
        params: query
    })
}

export function postServer(query) {
    return request({
        url: 'broker-server',
        method: 'post',
        data: query
    })
}
export function putServer(query) {
    return request({
        url: 'broker-server',
        method: 'put',
        data: query
    })
}
export function deleteServer(query) {
    return request({
        url: 'broker-server',
        method: 'delete',
        data: query
    })
}
