import {
    getServer,
    postServer,
    putServer,
    deleteServer,
} from "@services/server";

const state = {
    loading: false,
    serverData: [],
    serverHeader: [
        { text: "ID", value: "id", align: "left", width: "30px" },
        // { text: "Broker ID", value: "broker_id", align: "left" },
        {
            text: "Broker Name",
            value: "broker_name",
            align: "left",
            width: "110px",
        },
        {
            text: "Server Name",
            value: "server_name",
            align: "left",
            width: "110px",
        },
        {
            text: "Adapter ID",
            value: "adapter_id",
            align: "left",
            width: "110px",
        },
        {
            text: "Description",
            value: "description",
            align: "left",
            width: "140px",
        },
        { text: "Host", value: "host", align: "left", width: "110px" },
        // { text: "Login", value: "login", align: "left", width: "60px" },
        // { text: "Password", value: "password", align: "left", width: "110px" },
        { text: "Port", value: "port", align: "left", width: "60px" },
        {
            text: "Created At",
            value: "created_at",
            align: " d-none",
            width: "100px",
        },
        {
            text: "Updated At",
            value: "updated_at",
            align: " d-none",
            width: "100px",
        },
        { text: "Actions", value: "action", align: "left", width: "100px" },
    ],
    updated: false,
    csvFields: {
        ID: "id",
        "Broker ID": "broker_id",
        "Broker Name": "broker_name",
        "Server Name": "server_name",
        "Adapter ID": "adapter_id",
        Description: "description",
        Host: "host",
        // Login: "login",
        // Password: "password",
        Port: "port",
    },
};

const actions = {
    /**
     * Action to get books from api
     *
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  params  [params description]
     * @return  {[type]}          [return description]
     */
    getServerAction({ commit }, params) {
        commit("UPDATE_STATUS", false);
        commit("UPDATE_LOADING", true);
        getServer().then((res) => {
            let resortedData = []
            res.data.map((item)=>{
                if(item.server_name.toLowerCase().includes("demo")){
                    resortedData.push(item)
                }else{
                    resortedData.unshift(item)
                }
            })
            commit("UPDATE_SERVER", resortedData);
            commit("UPDATE_LOADING", false);
        });
    },
    postServerAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        postServer(params)
            .then((res) => {
                let snackbar = {
                    message: res.data.msg,
                    color: "green",
                    btnName: "Close",
                    status: true,
                    timeout: 2000,
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
                commit("UPDATE_LOADING", false);
                commit("UPDATE_STATUS", true);
            })
            .catch((e) => {
                commit("UPDATE_LOADING", false);
            });
    },
    /**
     * Update broker server
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  params  [params description]
     * @return  {[type]}          [return description]
     */
    putServerAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        putServer(params)
            .then((res) => {
                let snackbar = {
                    message: res.data.msg,
                    color: "green",
                    btnName: "Close",
                    status: true,
                    timeout: 2000,
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
                commit("UPDATE_LOADING", false);
                commit("UPDATE_STATUS", true);
            })
            .catch((e) => {
                commit("UPDATE_LOADING", false);
            });
    },
    deleteServerAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        deleteServer(params)
            .then((res) => {
                let snackbar = {
                    message: res.data.msg,
                    color: "green",
                    btnName: "Close",
                    status: true,
                    timeout: 2000,
                };
                commit("UPDATE_SNACKBAR", snackbar, { root: true });
                commit("UPDATE_LOADING", false);
                commit("UPDATE_STATUS", true);
            })
            .catch(({ response }) => {
                if (response.status == 406) {
                    let snackbar = {
                        message:
                            response.data.msg +
                            ", please check the book table before deleting this broker",
                        color: "red",
                        btnName: "Close",
                        status: true,
                        timeout: 4000,
                    };
                    commit("UPDATE_SNACKBAR", snackbar, { root: true });
                    commit("UPDATE_LOADING", false);
                }
            });
    },
};

const mutations = {
    /**
     * Update server
     *
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_SERVER(state, data) {
        state.serverData = data;
    },
    /**
     * Update loading
     *
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_STATUS(state, data) {
        state.updated = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
