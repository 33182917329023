import { getDataDepositWithdrawal } from "@services/alert/deposit-withdrawal";
import common from "@assets/js/common.js";
import dayjs from "dayjs";

const state = {
    autoRefreshTimeOffset: 1,
    loading: false,
    alertData: [],
    alertHeader: [
        { text: "Login", value: "login", align: "left" },
        { text: "Name", value: "name", align: "left" },
        { text: "Amount", value: "amount", align: "right" },
        { text: "Book", value: "book_name", align: "right" },
        { text: "Action", value: "action", align: "left" },
        {
            text: "Trigger Time",
            value: "trigger_time",
            align: "left",
            width: 170,
        },
    ],
    alertDataAmount: 0,
    csvHeader: {
        Login: "login",
        "Server Name": "server_name",
        Amount: "amount",
        "Trigger Time": "trigger_time",
    },
    selectedDate: null,
};

const actions = {
    /**
     * Fetch large volume data
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  data    [data description]
     * @return  {[type]}          [return description]
     */
    getDataDepositWithdrawalAction({ commit }, data) {
        commit("UPDATE_LOADING", true);
        getDataDepositWithdrawal(data).then((res) => {
            commit("UPDATE_ALERT_DATA", res.data.data);
            commit("UPDATE_AMOUNT", res.data.count);
            commit("UPDATE_LOADING", false);
        });
    },
    processDepositWithdrawal({ commit }, data) {
        const minimumAcceptableTime = dayjs(
            common.getMT4Time().format("YYYY-MM-DD")
        ).unix();
        if (
            state.selectedDate >= minimumAcceptableTime &&
            data.trigger_time >= minimumAcceptableTime
        ) {
            const exist = state.alertData.find(
                (item) =>
                    item.login == data.login &&
                    item.amount == data.amount &&
                    item.trigger_time == data.trigger_time
            );
            if (!exist) {
                data["new"] = true;
                commit("UPDATE_ALERT_DATA", [data, ...state.alertData]);
            }
        }
    },
};

const mutations = {
    /**
     * Update monitor data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_MONITOR_DATA(state, data) {
        state.monitorData = data;
    },
    /**
     * Update alert data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
    UPDATE_SELECTED_DATE(state, data) {
        state.selectedDate = dayjs(data).unix();
    },
    UPDATE_AMOUNT(state, data) {
        state.alertDataAmount = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
