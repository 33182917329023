import { getTick, postTick } from "@services/alert/tick";
import common from "@assets/js/common";
import dayjs from "dayjs";

const state = {
    autoRefreshTimeOffset: 30,
    loading: false,
    alertData: [],
    alertHeader: [
        { text: "Server", value: "server_name", align: "left" },
        { text: "Symbol", value: "symbol", align: "left" },
        { text: "Security", value: "security", align: "left" },
        {
            text: "Threshold",
            value: "seconds_threshold",
            align: "right",
        },
        {
            text: "Gap",
            value: "gap",
            align: "right",
        },
        {
            text: "Action",
            value: "action",
            align: "left",
        },
        {
            text: "Last Update Time",
            value: "last_update_time",
            width: 170,
            align: "left",
        },
        {
            text: "Trigger Time",
            value: "current_time",
            width: 170,
            align: "left",
        },
    ],
    alertDataAmount: 0,
    csvHeader: {
        Server: "server_name",
        Symbol: "symbol",
        Security: "security",
        Threshold: "seconds_threshold",
        Gap: "gap",
        "Last Update Time": "last_update_time",
        "Trigger Time": "current_time",
    },
};

const actions = {
    getTickAction({ commit },params) {
        commit("UPDATE_LOADING", true);
        getTick(params).then((res) => {
            res.data.data.map((item)=>item.loading = false)
            commit("UPDATE_ALERT_DATA", res.data.data);
            commit("UPDATE_AMOUNT", res.data.count);
            commit("UPDATE_LOADING", false);
        });
    },
    processTick({ commit }, data) {
        const minimumAcceptableTime = dayjs(
            common.getMT4Time().format("YYYY-MM-DD")
        ).unix();
        if (data.current_time >= minimumAcceptableTime) {
            const exist = state.alertData.find(
                (item) =>
                    item.server_name == data.server_name &&
                    item.symbol == data.symbol &&
                    item.security == data.security
            );
            if (!exist) {
                data["new"] = true;
                commit("UPDATE_ALERT_DATA", [data, ...state.alertData]);
            }
        }
    },
    updateSymbolAction(_,params) {
        return postTick(params)
    },
};

const mutations = {
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
    UPDATE_AMOUNT(state, data) {
        state.alertDataAmount = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
