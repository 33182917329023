import {
    getDownloadStatus,
    downloadFileUpdate,
} from "@services/search/downloadStatus";

const state = {
    headers: [
        { text: "Id", value: "id", align: "left", width: 50 },
        {
            text: "Request Type",
            value: "request_type",
            align: "left",
            width: 80,
        },
        {
            text: "Request Params",
            value: "request_param",
            align: "left",
            width: 120,
        },
        { text: "Status", value: "download_status", align: "left", width: 120 },
        { text: "Created At", value: "created_at", align: "left", width: 120 },
        { text: "Updated At", value: "updated_at", align: "left", width: 120 },
    ],
    loading: false,
    tradesList: [],
};

const actions = {
    getDownloadStatusAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        commit("UPDATE_HISTORY", []);
        getDownloadStatus(params)
            .then((res) => {
                const processedResult = res.data.map((item) => {
                    const loadedItem = JSON.parse(item.request_param);
                    let returnString = "";
                    Object.keys(loadedItem).map((key) => {
                        if (
                            key !== "offset" &&
                            key !== "page" &&
                            key !== "downloadRequest"
                        ) {
                            returnString +=
                                "<b>" + key.replaceAll("_", " ") + ": </b>";
                            returnString += loadedItem[key] + "<br/>";
                        }
                    });

                    item.request_param = returnString;
                    return item;
                });

                commit("UPDATE_HISTORY", processedResult);
                commit("UPDATE_LOADING", false);
            })
            .catch((e) => {
                console.log(e);
                commit("UPDATE_HISTORY", []);
                commit("UPDATE_LOADING", false);
            });
    },
    downloadFileAction({ commit }, item) {
        const url = item["download_status"].split(",")[0];
        const bucket = item["download_status"].split(",")[1];
        const key = item["download_status"].split(",")[2];
        downloadFileUpdate({ id: item["id"], bucket: bucket, key: key })
            .then((res) => {
                if (res.status === 200) {
                    const a = document.createElement("a");
                    a.href = url;
                    a.target = "_blank";
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                }
            })
            .catch((e) => {
                console.log(e);
                store.state.snackcolor = "red";
                store.state.snackbar = true;
                store.state.snackmsg = "Internal Server Error";
            });
    },
};

const mutations = {
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_HISTORY(state, data) {
        state.tradesList = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
