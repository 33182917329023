import request from "@utils/request";

export function getBooksPk(query) {
    return request({
        url: 'book/pk',
        method: 'get',
        params: query
    })
}

export function getBooks(query) {
    return request({
        url: 'book',
        method: 'get',
        params: query
    })
}

export function postBooks(query) {
    return request({
        url: 'book',
        method: 'post',
        data: query
    })
}
export function putBooks(query) {
    return request({
        url: 'book',
        method: 'put',
        data: query
    })
}
export function deleteBooks(query) {
    return request({
        url: 'book',
        method: 'delete',
        data: query
    })
}
