import { getBooks, postBooks, putBooks, deleteBooks } from "@services/books";

const state = {
    loading: false,
    bookData: [],
    bookHeader: [
        { text: "ID", value: "book_id", align: "left" },
        { text: "Broker Id", value: "broker_id", align: " d-none" },
        { text: "Broker Name", value: "broker_name", align: "left" },
        { text: "Book Name", value: "book_name", align: "left" },
        { text: "Parent Book Id", value: "parent_book_id", align: " d-none" },
        { text: "Parent Book Name", value: "parent_book_name", align: "left" },
        { text: "Accessible", value: "accessible", align: "left" },
        { text: "Created At", value: "created_at", align: "left" },
        { text: "Updated At", value: "updated_at", align: "left" },
        { text: "Actions", value: "action", align: "left" },
    ],
    updated: false,
    returnMsg: "",
    csvFields: {
        ID: "book_id",
        "Broker ID": "broker_id",
        "Book Name": "book_name",
        "Parent Book ID": "parent_book_id",
        Accessible: "accessible",
    },
};

const actions = {
    /**
     * Action to get books from api
     *
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  params  [params description]
     * @return  {[type]}          [return description]
     */
    getBooksAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        getBooks().then((res) => {
            commit("UPDATE_BOOKS", res.data);
            commit("UPDATE_LOADING", false);
        });
    },
    postBooksAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        postBooks(params)
            .then((res) => {
                if (res.status == 200) {
                    commit("UPDATE_STATUS", true);
                    commit("UPDATE_MSG", res.data.msg);
                    commit("UPDATE_LOADING", false);
                }
            })
            .catch(() => {
                commit("UPDATE_LOADING", false);
            });
    },
    putBooksAction({ commit }, params) {
        commit("UPDATE_LOADING", true);
        putBooks(params)
            .then((res) => {
                if (res.status == 200) {
                    commit("UPDATE_STATUS", true);
                    commit("UPDATE_MSG", res.data.msg);
                    commit("UPDATE_LOADING", false);
                }
            })
            .catch(() => {
                commit("UPDATE_LOADING", false);
            });
    },
    deleteBooksAction({ commit }, id) {
        const params = { book_id: id };
        commit("UPDATE_LOADING", true);
        deleteBooks(params)
            .then((res) => {
                if (res.status == 200) {
                    commit("UPDATE_MSG", res.data.msg);
                    commit("UPDATE_STATUS", true);
                    commit("UPDATE_LOADING", false);
                }
            })
            .catch(({ response }) => {
                if (response.status == 406) {
                    let snackbar = {
                        message:
                            response.data.msg +
                            ", please check the routing table before deleting this book",
                        color: "red",
                        btnName: "Close",
                        status: true,
                        timeout: 4000,
                    };
                    commit("UPDATE_SNACKBAR", snackbar, { root: true });
                    commit("UPDATE_LOADING", false);
                }
            });
    },
};

const mutations = {
    /**
     * Update books
     *
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_BOOKS(state, data) {
        state.bookData = data;
    },
    /**
     * Update loading
     *
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_STATUS(state, data) {
        state.updated = data;
    },
    UPDATE_MSG(state, data) {
        state.returnMsg = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
