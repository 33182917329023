import sortTable from "./sort";

const install = function (Vue) {
    Vue.directive("sortable-table", sortTable);
};

if (window.Vue) {
    window["sortable-table"] = sortTable;
    Vue.use(install); // eslint-disable-line
}

sortTable.install = install;
export default sortTable;
