import { getVolume } from "@services/search/volume";

const state = {
    csvHeader: {
        login: "Login",
        book: "Book",
        total_volume: "Total Volume"
    },
    filterOrderBy: ["volume"],
    headers: [
        { text: "Login", value: "login", align: "left", width: 100 },
        { text: "Book", value: "book", align: "left", width: 100 },
        {
            text: "Total Volume",
            value: "volume",
            align: "right",
            width: 100
        }
    ],
    filterShowResult: [100, 200, 400, 600, 1000, 2000],
    tradesList: [],
    loading: false,
};

const actions = {
    getVolumeAction({ commit, state }, params) {
        commit("UPDATE_lOADING",true)
        commit("UPDATE_VOLUME_HISTORY", []);
        getVolume(params).then(res => {
            console.log(res)
            const data = res.data;
            commit("UPDATE_VOLUME_HISTORY", data);
            commit("UPDATE_lOADING",false)
        });
    }
};

const mutations = {
    UPDATE_VOLUME_HISTORY(state, data) {
        state.tradesList = data;
    },
    UPDATE_lOADING(state,data){
        state.loading = data
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
