import request from "@utils/request";

export function getSymbol(query) {
    return request({
        url: 'symbol',
        method: 'get',
        params: query
    })
}

export function getSymbolDP(){
    return request({
        url: "symbol_dp",
        method: 'get'
    })
}