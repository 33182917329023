export function getToken(token) {
    return localStorage.getItem(token);
}

export function setToken(token) {
    return localStorage.setItem("token", token)
}

export function removeToken() {
    return localStorage.removeItem("token")
}