import common from "@assets/js/common";
import { getDataLargeVolume } from "@services/alert/large-volume";
import dayjs from "dayjs";

const state = {
    autoRefreshTimeOffset: 1,
    loading: false,
    alertData: [],
    alertHeader: [
        { text: "Login", value: "login", align: "left" },
        { text: "Name", value: "name", align: "left" },
        { text: "Symbol", value: "symbol", align: "left" },
        { text: "Books", value: "books", align: "left" },
        { text: "Volume", value: "volume", align: "right" },
        { text: "Open Time", value: "open_time", align: "left", width: 170, },
        { text: "Close Time", value: "close_time", align: "left", width: 170, },
        { text: "Action", value: "action", align: "left" },
    ],
    alertDataAmount: 0,
    csvHeader: {
        Login: "login",
        Symbol: "symbol",
        Books: "books",
        Volume: "volume",
        "Open Time": "open_time",
        "Close Time": "close_time",
        "Server Name": "server_name",
        Order: "order",
        "Volume Threshold": "threshold_volume",
        "Trigger Time": "trigger_time",
    },
    selectedDate: null,
};

const actions = {
    /**
     * Fetch large volume data
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  data    [data description]
     * @return  {[type]}          [return description]
     */
    getDataLargeVolumeAction({ commit }, data) {
        commit("UPDATE_LOADING", true);
        getDataLargeVolume(data).then((res) => {
            res.data.data.forEach((item) => {
                item["books"] = item["books"].replace(/[[\]]/g, "");
            });
            commit("UPDATE_ALERT_DATA", res.data.data);
            commit("UPDATE_AMOUNT", res.data.count);
            commit("UPDATE_LOADING", false);
        });
    },
    processLargeVolume({ commit }, data) {
        const minimumAcceptableTime = dayjs(
            common.getMT4Time().format("YYYY-MM-DD")
        ).unix();
        if (
            state.selectedDate >= minimumAcceptableTime &&
            data.trigger_time >= minimumAcceptableTime
        ) {
            data.books = data.books.replace(/[[\]]/g, "");
            const exist = state.alertData.find(
                (item) =>
                    item.login == data.login &&
                    item.books == data.books &&
                    item.symbol == data.symbol &&
                    item.trigger_time == data.trigger_time
            );
            if (!exist) {
                data["new"] = true;
                commit("UPDATE_ALERT_DATA", [data, ...state.alertData]);
            }
        }
    },
};

const mutations = {
    /**
     * Update alert data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
    UPDATE_SELECTED_DATE(state, data) {
        state.selectedDate = dayjs(data).unix();
    },
    UPDATE_AMOUNT(state, data) {
        state.alertDataAmount = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
