import { getLoginSummary } from "@services/statistic/login-summary";

const state = {
    loginHistoryChartSeriesData: [],
    loginHistoryChartLegendData: [],
    loginHistoryChartTimeDate: [],
    loadChartHistory: false,
    loadSummary: false,
    loginSummary: {}
};

const actions = {
    /**
     * load login's statistic
     * @param   {[type]}  item  [item description]
     * @return  {[type]}        [return description]
     */
    loadLoginHistoryChart({ commit }, params) {

        commit("UPDATE_LOAD_HISTORY_CHART_SERIES_DATA", []);
        commit("UPDATE_LOAD_HISTORY_CHART_LEGEND_DATA", []);
        commit("UPDATE_LOAD_HISTORY_CHART_TIME_DATE", []);
        commit("UPDATE_LOAD_CHART_HISTORY", false);
        commit("UPDATE_LOAD_SUMMARY", false);

        getLoginSummary(params).then((res) => {
            commit("UPDATE_LOAD_HISTORY_CHART_TIME_DATE", res.data.xdata);

            let loginHistoryChartLegendData = [];
            let loginHistoryChartSeriesData = [];

            let chartDateTime = res.data.xdata;

            if (chartDateTime != undefined) {
                chartDateTime = chartDateTime.map(function (str) {
                    return str.substring(2, str.length - 9);
                });

                const daily_pnl = {
                    name: "Daily PNL", type: "line", symbolSize: 8, emphasis: { scale: false }, 
                    symbol: "circle", yAxisIndex: 2, data: res.data.ydata.daily_pnl
                };
                const balance = {
                    name: "Balance", type: "line", emphasis: { scale: false }, symbol: "circle",
                    data: res.data.ydata.balance
                };
                const equity = {
                    name: "Equity", type: "line", emphasis: { scale: false },
                    symbol: "circle", data: res.data.ydata.equity
                };
                const dpm = {
                    name: "DPM", type: "bar", emphasis: { scale: false }, symbol: "circle", yAxisIndex: 1,
                    data: res.data.ydata.dpm
                };

                loginHistoryChartLegendData.push(daily_pnl.name);
                loginHistoryChartSeriesData.push(daily_pnl);
                loginHistoryChartLegendData.push(balance.name);
                loginHistoryChartSeriesData.push(balance);
                loginHistoryChartLegendData.push(equity.name);
                loginHistoryChartSeriesData.push(equity);
                loginHistoryChartLegendData.push(dpm.name);
                loginHistoryChartSeriesData.push(dpm);
            }

            commit("UPDATE_LOAD_HISTORY_CHART_SERIES_DATA", loginHistoryChartSeriesData);
            commit("UPDATE_LOAD_HISTORY_CHART_LEGEND_DATA", loginHistoryChartLegendData);
            
            setTimeout(() => {
                commit("UPDATE_LOAD_CHART_HISTORY", true);
            }, 1000);
        });
    },
};

const mutations = {
    UPDATE_LOGIN_SUMMARY(state, data) {
        state.loginSummary = data;
    },
    /**
     * Update load chart history
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOAD_CHART_HISTORY(state, data) {
        state.loadChartHistory = data;
    },
    /**
     * Update load summary
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOAD_SUMMARY(state, data) {
        state.loadSummary = data;
    },
    UPDATE_LOAD_HISTORY_CHART_SERIES_DATA(state, data) {
        state.loginHistoryChartSeriesData = data;
    },
    UPDATE_LOAD_HISTORY_CHART_LEGEND_DATA(state, data) {
        state.loginHistoryChartLegendData = data;
    },
    UPDATE_LOAD_HISTORY_CHART_TIME_DATE(state, data) {
        state.loginHistoryChartTimeDate = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}