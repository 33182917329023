import { getTickSpread } from "@services/search/tick-spread";

const state = {
    sessionLists: ["ALL", "US", "EU", "ASIAN"],
    loading: false,
    btnLoading: false,
    tickSpreadHeader: [
        { text: "Symbol", value: "symbol", align: "left", width: 120 },
        { text: "Session", value: "session", align: "left", width: 100 },
        { text: "Avg Spread", value: "spread", align: "right", width: 120 },
        { text: "Date", value: "record_time", align: "left", width: 170 },
        { text: "Max Spread", value: "max", align: "right", width: 120 },
        { text: "Min Spread", value: "min", align: "right", width: 120 },
        { text: "Total Tick", value: "count", align: "right", width: 120 },
        { text: "Created At", value: "created_at", align: "left", width: 170 },
        { text: "Updated At", value: "updated_at", align: "left", width: 170 },
    ],
    tickSpreadData: []
};

const actions = {
    getTickSpreadActions({ commit, state }, params) {
        commit("UPDATE_LOADING", true);
        commit("UPDATE_BTN_LOADING", true);
        commit("UPDATE_TICK_SPREAD_DATA", []);
        getTickSpread(params).then((res) => {
            
            console.log(res.data);

            commit("UPDATE_TICK_SPREAD_DATA", res.data);
            commit("UPDATE_LOADING", false);
            commit("UPDATE_BTN_LOADING", false);
        })
    },
};

const mutations = {
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_BTN_LOADING(state, data) {
        state.btnLoading = data;
    },
    UPDATE_TICK_SPREAD_DATA(state, data) {
        state.tickSpreadData = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
