<template>
    <div class="custom-menu" v-show="!VUETIFY_BREAKPOINT.mobile">
        <v-menu
            offset-y
            v-for="(item, index) in items"
            :key="index"
            v-if="item.active == 1"
            open-on-hover
            :close-on-content-click="false"
            class="custom-menu"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="default"
                    v-on:click="redirect(item)"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    style="border-radius: 0"
                >
                    {{ item.text }}
                </v-btn>
            </template>
            <v-list
                v-if="item.children"
                class="pl-0 pr-0 pt-0 pb-0"
                style="text-align: left"
            >
                <v-btn
                    block
                    v-on:click="redirect(submenu)"
                    v-if="submenu.active == 1"
                    style="border-radius: 0px"
                    v-for="(submenu, subindex) in item.children"
                    :key="subindex"
                >
                    {{ submenu.text }}
                </v-btn>
            </v-list>
        </v-menu>

        <HomeProfile v-if="isHomePage" />
        <AlertProfile v-if="isAlertPage" />

        <!-- home page -->
        <v-tooltip v-if="isHomePage" left>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    id="homeTutorialIcon"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    class="mr-2"
                    style="display: block; float: right"
                    @click="startTutorial"
                    >mdi-school</v-icon
                >
            </template>
            <span>Click to start tutorial</span>
        </v-tooltip>
        <!-- alert page -->
        <v-tooltip v-if="isAlertBrowserPage" left>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    class="mr-2"
                    style="display: block; float: right"
                    @click="startTutorial"
                    >mdi-school</v-icon
                >
            </template>
            <span>Click to start tutorial</span>
        </v-tooltip>
        <!-- search page -->
        <v-tooltip v-if="isSearchPage" left>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    class="mr-2"
                    style="display: block; float: right"
                    @click="startTutorial"
                    >mdi-school</v-icon
                >
            </template>
            <span>Click to start tutorial</span>
        </v-tooltip>
        <!-- lp page -->
        <v-tooltip v-if="isLpPage && (lpModule === 0 || lpModule === 1)" left>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    class="mr-2"
                    style="display: block; float: right"
                    @click="startTutorial"
                    >mdi-school</v-icon
                >
            </template>
            <span>Click to start tutorial</span>
        </v-tooltip>
        <!-- ib page -->
        <v-tooltip v-if="isIBPage" left>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    class="mr-2"
                    style="display: block; float: right"
                    @click="startTutorial"
                    >mdi-school</v-icon
                >
            </template>
            <span>Click to start tutorial</span>
        </v-tooltip>
    </div>
</template>

<script>
import AlertProfile from "../views/alert/ComponentAlertPageProfile.vue";
import HomeProfile from "../views/home/ComponentHomePageProfile.vue";
import { mapMutations, mapActions, mapState } from "vuex";
export default {
    name: "dataMenu",
    components: {
        HomeProfile,
        AlertProfile,
    },
    data() {
        return {
            currentRoute: [],
            items: [
                {
                    icon: "mdi-book-multiple",
                    text: "Book Monitor",
                    name: "book",
                    type: "link",
                    ref: "/",
                    active: 0,
                },
                {
                    icon: "mdi-book-multiple",
                    text: "LP",
                    name: "lp",
                    type: "link",
                    ref: "/lp",
                    active: 0,
                },
                {
                    icon: "mdi-book-multiple",
                    text: "Alert",
                    name: "alert",
                    type: "link",
                    ref: "/alert/v2",
                    active: 0,
                },
                {
                    icon: "mdi-chevron-down",
                    text: "Search",
                    name: "search",
                    type: "link",
                    ref: "/search",
                    active: 0,
                },
                {
                    icon: "mdi-chevron-down",
                    text: "Grafana",
                    name: "k8s",
                    type: "link",
                    ref:
                        (document
                            .querySelector("body")
                            .getAttribute("socketapi") ||
                            process.env.VUE_APP_SOCKET_API) + "/grafana",
                    active: 0,
                },
                {
                    icon: "mdi-book-multiple",
                    text: "Copy Trade",
                    name: "copyTrade",
                    type: "link",
                    ref: "/copy-trade",
                    active: 0,
                },
                {
                    icon: "mdi-book-multiple",
                    text: "IB Monitor",
                    name: "ibMonitor",
                    type: "link",
                    ref: "/ib-monitor",
                    active: 0,
                },
            ],
        };
    },
    computed: {
        ...mapState("LP", ["lpModule"]),
        currentPath() {
            return localStorage.getItem("currentPath");
        },
        isAlertPage() {
            return ["AlertOverviewV2", "AlertOverviewMobile"].includes(
                this.$route.name
            );
        },
        isAlertBrowserPage() {
            return this.$route.path === "/alert/v2";
        },
        isHomePage() {
            return this.$route.path === "/";
        },
        isIBPage() {
            return this.$route.path === "/ib-monitor";
        },
        isSearchPage() {
            return this.$route.path === "/search";
        },
        isLpPage() {
            return this.$route.path === "/lp";
        },
    },
    // watch: {
    //     "$route.path": {
    //         handler(nv) {
    //             console.log(nv)
    //         },
    //     },
    // },
    methods: {
        ...mapMutations("IB", ["UPDATE_IB_TUTORIAL_STATUS"]),
        ...mapActions("Home", ["updateHomeTutorialAction"]),
        ...mapActions("Alert", ["updateAlertTutorialAction"]),
        ...mapActions("RawHistory", ["updateSearchTutorialAction"]),
        ...mapActions("LP", ["updateLpTutorialAction"]),
        startTutorial() {
            if (this.$route.path === "/ib-monitor") {
                this.UPDATE_IB_TUTORIAL_STATUS(true);
            }
            if (this.$route.path === "/") {
                this.updateHomeTutorialAction(true);
            }
            if (this.$route.path === "/alert/v2") {
                this.updateAlertTutorialAction(true);
            }
            if (this.$route.path === "/search") {
                this.updateSearchTutorialAction(true);
            }
            if (this.$route.path === "/lp") {
                this.updateLpTutorialAction(true);
            }
        },
        redirect(data) {
            if (data.children) return;

            if (data.name === "k8s") {
                window.open(data.ref, "_blank");
            } else {
                this.$router.push(data.ref).catch(() => {});
            }
        },
    },
    mounted() {
        this.currentRoute = this.$route.meta.bread;

        const data = JSON.parse(
            localStorage.getItem("permission")
        ).frontPermission;

        for (const item of this.items) {
            const name = item.name;
            if (data[name]) {
                item.active = 1;
                if (data[name].child.length > 0) {
                    const children = data[name].child;
                    if (item.children) {
                        for (const child of item.children) {
                            if (children.includes(child.name)) {
                                child.active = 1;
                            }
                        }
                    }
                }
            }
        }
    },
};
</script>
<style>
.v-list .v-list-item:hover {
    background: #0091da;
    cursor: pointer;
}
.shake {
    animation: shake 1s infinite alternate;
}

@keyframes shake {
    0% {
        transform: rotate(0deg) scale(1);
    }
    25% {
        transform: rotate(10deg) scale(1.5);
    }
    50% {
        transform: rotate(0eg) scale(1);
    }
    75% {
        transform: rotate(-10deg) scale(1.5);
    }
    100% {
        transform: rotate(0deg) scale(1);
    }
}
</style>
