import axios from "axios";

export function getPumpingStatus() {
    const config = {
        headers: {
            Token: localStorage.getItem("token"),
        },
    };

    return axios.get(
        (document.querySelector("body").getAttribute("socketapi") ||
            process.env.VUE_APP_SOCKET_API) +
                "/systemStatus/pumpingStatus",
        config
    );
}
