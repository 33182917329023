import { getManagerNodeStatus, getManagerDroppedTasks, getManagerGeneratedTasks } from "@services/k8s/manager";
import { getPublisherBookDroppedTasks, getPublisherUserDroppedTasks } from "@services/k8s/publisher";

const state = {
    tableHeaderManagerNodesStatus: [
        { text: "Address", value: "address", align: "left" },
        { text: "Broker", value: "broker", align: "left" },
        { text: "Created At", value: "created_at", align: "left" },
        { text: "Last Seen", value: "last_seen", align: "left" },
        { text: "Name", value: "name", align: "left" },
        { text: "Peer Port", value: "peer_port", align: "left" },
        { text: "Task Port", value: "task_port", align: "left" },
        { text: "Worker Count", value: "worker_count", align: "left" },
    ],
    dataManagerNodesStatus: [],
    
    tableHeaderManagerDroppedTasks: [
        { text: "num", value: "num", align: "left" },
        { text: "minutes", value: "minutes", align: "left" },
    ],
    dataManagerDroppedTasks: [],
    
    tableHeaderManagerGeneratedTasks: [
        { text: "num", value: "num", align: "left" },
        { text: "minutes", value: "minutes", align: "left" },
    ],
    dataManagerGeneratedTasks: [],
    dataPublisherBookDroppedTasks: [],
    dataPublisherUserDroppedTasks: [],
};

const actions = {
    /**
     * Call api to get manager node status
     * @param {*} param0 
     */
    getManagerNodeStatusAction({ commit }) {
        getManagerNodeStatus().then((res) => {
            commit("UPDATE_DATA_MANAGER_NODES_STATUS", res.data);
        })
    },
    /**
     * Call api to get manager node status
     * @param {*} param0 
     */
    getManagerDroppedTasksAction({ commit }) {
        getManagerDroppedTasks().then((res) => {
            commit("UPDATE_DATA_MANAGER_DROPPED_TASKS", res.data);
        })
    },
    /**
     * Call api to get manager node status
     * @param {*} param0 
     */
    getManagerGeneratedTasksAction({ commit }) {
        getManagerGeneratedTasks().then((res) => {
            commit("UPDATE_DATA_MANAGER_GENERATED_TASKS", res.data);
        })
    },
    /**
     * Call api to get manager node status
     * @param {*} param0 
     */
    getPublisherBookDroppedTasksAction({ commit }) {
        getPublisherBookDroppedTasks().then((res) => {
            commit("UPDATE_DATA_PUBLISHER_BOOK_DROPPED_TASKS", res.data);
        })
    },
    /**
     * Call api to get manager node status
     * @param {*} param0 
     */
    getPublisherUserDroppedTasksAction({ commit }) {
        getPublisherUserDroppedTasks().then((res) => {
            commit("UPDATE_DATA_PUBLISHER_USER_DROPPED_TASKS", res.data);
        })
    },
};

const mutations = {
    /**
     * Update data manager nodes
     * @param { } state 
     * @param { } data 
     */
    UPDATE_DATA_MANAGER_NODES_STATUS(state, data) {
        state.dataManagerNodesStatus = data;
    },
    /**
     * Update data manager dropped
     * @param {*} state 
     * @param {*} data 
     */
    UPDATE_DATA_MANAGER_DROPPED_TASKS(state, data) {
        state.dataManagerDroppedTasks = data;
    },
    /**
     * Update data manager generated
     * @param {*} state 
     * @param {*} data 
     */
    UPDATE_DATA_MANAGER_GENERATED_TASKS(state, data) {
        state.dataManagerGeneratedTasks = data;
    },
    /**
     * Update data publisher book dropped
     * @param {*} state 
     * @param {*} data 
     */
    UPDATE_DATA_PUBLISHER_BOOK_DROPPED_TASKS(state, data) {
        state.dataPublisherBookDroppedTasks = data;
    },
    /**
     * Update data publisher user dropped
     * @param {*} state 
     * @param {*} data 
     */
    UPDATE_DATA_PUBLISHER_USER_DROPPED_TASKS(state, data) {
        state.dataPublisherUserDroppedTasks = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}