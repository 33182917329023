export default {
    /**
     * use to validate common name field
     * @param   {[type]}  timestamp  [timestamp description]
     * @return  {[type]}             [return description]
     */
    commonStringValidator(data) {
        if (data == null) return false;
        if (data.trim() == "") return false;
        return true;
    },
    cleanUpStringData(data) {
        const cleanedData = data.trim().replace(/\s+/g, " ");
        return cleanedData;
    },
    removeAllWhiteSpace(data) {
        const cleanedData = data.replaceAll(" ", "");
        return cleanedData;
    },
    isAnyFieldEmpty(listOfField) {
        for (const item of listOfField) {
            if (item === "" || item === undefined || item === null) {
                return true;
            }
        }
        return false;
    },
    isValidJson(input) {
        try {
            if (input.constructor === String) {
                const test = JSON.parse(input);
            }
            return true;
        } catch (e) {
            return false;
        }
    },
};

export const rules = {
    required: (value) => {
        if (value == null || !!value) {
            return true;
        }
        return "Required.";
    },
    pwdRequired: (value) => {
        if (value == null) return true;
        return !!value || "Required.";
    },
    email: (value) => {
        if (value == null) return true;
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
    },
    min6: (value) =>
        value == null ||
        value.length >= 6 ||
        value.length == 0 ||
        "Minimum 6 characters",
    mobile: (value) => {
        if (value == null || value.trim() == "") return true;
        const spaceRemoved = value.replaceAll(" ", "");
        const pattern =
            /^[\+]?[(]?[0-9]{1,3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        return pattern.test(spaceRemoved) || "Invalid mobile.";
    },
    pwdMatch: (value, newPwd) => {
        if (value == null || value == "") return false;
        if (value == newPwd) {
            return true;
        }
        return "Password doesn't match";
    },
    ipAddress: (value) => {
        if (value == null) return true;
        const pattern = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
        return pattern.test(value) || "Invalid IP address.";
    },
    port: (value) => {
        if (value == null) return true;
        const pattern =
            /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;
        return pattern.test(value) || "Invalid port config.";
    },
    int: (value) => {
        if (value == null) return true;
        const pattern = /^\d+$/;
        return (
            pattern.test(value) || "Please provide an integer for this field."
        );
    },
    number: (value) => {
        if (value === null) return true;
        const pattern = /^[+-]?\d+(\.\d+)?$/;
        return pattern.test(value) || "Please provide a number for this field.";
    },
    pendingRatioOrPoint: (value) => {
        if (value === null) return true;
        const pattern = /^([0-9]+(\.[0-9]+)?\;)+$/;
        return pattern.test(value) || "Please provide a proper sequence.";
    },
    patternMatch: (points, ratios) => {
        if (points == null || points == "") return false;
        if (points.split(";").length == ratios.split(";").length) {
            return true;
        }
        return "Pattern doesn't match with ratio";
    },
};
