import request from "@utils/request";

export function getConfig(type) {
    return request({
        url: "alert/config/" + type,
        method: "get",
    });
}

export function getConfigWithCancelToken(type, cancelTokenSource) {
    return request({
        url: "alert/config/" + type,
        method: "get",
        cancelTokenSource: cancelTokenSource,
    });
}

export function updateConfig(type, data) {
    return request({
        url: "alert/config/" + type,
        method: "put",
        data: data,
    });
}

export function createConfig(type, data) {
    return request({
        url: "alert/config/" + type,
        method: "post",
        data: data,
    });
}

export function deleteConfig(type, data) {
    return request({
        url: "alert/config/" + type,
        method: "delete",
        data: data,
    });
}

export function getTickMissingConfig(type, data) {
    return request({
        url: "alert/config/tick/missing-config",
        method: "get",
        data: data,
    });
}
