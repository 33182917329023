import request from "@utils/request";

export function getPageProfile(query) {
    return request({
        url: "page-profile",
        method: "get",
        params: query,
    });
}

export function postPageProfile(query) {
    return request({
        url: "page-profile",
        method: "post",
        data: query,
    });
}
export function putPageProfile(query) {
    return request({
        url: "page-profile",
        method: "put",
        data: query,
    });
}
export function deletePageProfile(query) {
    return request({
        url: "page-profile",
        method: "delete",
        data: query,
    });
}

export function getProfile(query) {
    return request({
        url: "page-profile-management",
        method: "get",
        params: query,
    });
}

export function putProfile(query) {
    return request({
        url: "page-profile-management",
        method: "put",
        data: query,
    });
}
