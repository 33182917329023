import request from "@utils/request";

export function getCopyTradeConfig(query) {
    return request({
        url: 'copy-trade',
        method: 'get',
        params: query
    })
}

export function updateCopyTradeConfig(data){
    return request({
        url: 'copy-trade',
        method: 'put',
        data: data
    })
}

export function createCopyTradeConfig(data){
    return request({
        url: 'copy-trade',
        method: 'post',
        data: data
    })
}

export function deleteCopyTradeConfig(data){
    return request({
        url: 'copy-trade',
        method: 'delete',
        data: data
    })
}