import common from "@assets/js/common";
import dayjs from "dayjs";
import { getDataWeightedVolume } from "@services/alert/weighted-volume";

const state = {
    autoRefreshTimeOffset: 1,
    loading: false,
    alertData: [],
    alertHeader: [
        { text: "Login", value: "login", align: "left" },
        { text: "Book", value: "books", align: "left" },
        { text: "Closed Volume", value: "weighted_volume", align: "right" },
        { text: "Closed Profit", value: "closed_profit_total", align: "right" },
        {
            text: "Closed Profit EOD",
            value: "closed_profit_total_eod",
            align: "right",
        },
    ],
    csvHeader: {
        Login: "login",
        "Server Name": "server_name",
        Book: "books",
        "Closed Volume": "weighted_volume",
        "Closed Profit": "closed_profit_total",
        "Closed Profit EOD": "closed_profit_total_eod",
        "Closed Volume Threshold": "weighted_volume_threshold",
        "Trigger Time": "trigger_time",
    },
    selectedDate: null,
};

const actions = {
    /**
     * Fetch large volume data
     * @param   {[type]}  commit  [commit description]
     * @param   {[type]}  data    [data description]
     * @return  {[type]}          [return description]
     */
    getDataWeightedVolumeAction({ commit }, data) {
        commit("UPDATE_LOADING", true);
        getDataWeightedVolume(data).then((res) => {
            res.data.forEach((item) => {
                item.books = item.books.replace(/[[\]]/g, "");
            });
            commit("UPDATE_ALERT_DATA", res.data);
            commit("UPDATE_LOADING", false);
        });
    },
    processWeightedVolume({ commit }, data) {
        const minimumAcceptableTime = dayjs(
            common.getMT4Time().format("YYYY-MM-DD")
        ).unix();
        if (
            state.selectedDate >= minimumAcceptableTime &&
            data.trigger_time >= minimumAcceptableTime
        ) {
            data.books = data.books.replace(/[[\]]/g, "");
            const exist = state.alertData.find(
                (item) =>
                    item.login == data.login &&
                    item.books == data.books &&
                    item.closed_profit == data.closed_profit &&
                    item.closed_profit_eod == data.closed_profit_eod
            );
            if (!exist) {
                data["new"] = true;
                commit("UPDATE_ALERT_DATA", [data, ...state.alertData]);
            }
        }
    },
};

const mutations = {
    /**
     * Update monitor data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_MONITOR_DATA(state, data) {
        state.monitorData = data;
    },
    /**
     * Update alert data
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_ALERT_DATA(state, data) {
        state.alertData = data;
    },
    /**
     * Update loading
     * @param   {[type]}  state  [state description]
     * @param   {[type]}  data   [data description]
     * @return  {[type]}         [return description]
     */
    UPDATE_LOADING(state, data) {
        state.loading = data;
    },
    UPDATE_ALERT_HEADER(state, data) {
        state.alertHeader = data;
    },
    UPDATE_SELECTED_DATE(state, data) {
        state.selectedDate = dayjs(data).unix();
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
