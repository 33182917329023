import request from "@utils/socket/request";

export function getPublisherBookDroppedTasks(query) {
    return request({
        url: 'system-monitor-svc/publisher-book-dropped_tasks',
        method: 'get',
        params: query
    })
}

export function getPublisherUserDroppedTasks(query) {
    return request({
        url: 'system-monitor-svc/publisher-user-dropped_tasks',
        method: 'get',
        params: query
    })
}