import request from "@utils/socket/request";

export function getManagerNodeStatus(query) {
    return request({
        url: 'system-monitor-svc/manager-nodes-status',
        method: 'get',
        params: query
    })
}

export function getManagerDroppedTasks(query) {
    return request({
        url: 'system-monitor-svc/manager-dropped-tasks',
        method: 'get',
        params: query
    })
}

export function getManagerGeneratedTasks(query) {
    return request({
        url: 'system-monitor-svc/manager-generated-tasks',
        method: 'get',
        params: query
    })
}