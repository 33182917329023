import request from "@utils/request";

export function getLPInfo(query) {
    return request({
        url: 'lp/info',
        method: 'get',
        params: query
    })
}

export function putLPInfo(query) {
    return request({
        url: 'lp/info',
        method: 'put',
        data: query
    })
}